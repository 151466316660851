import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {PainPointService} from '../../_service/statistics/pain-point.service';
import {PainPoint} from '../../_model/bean/PainPoint';
import { FeedbackTableRangeDates } from '../../_model/FeedbackTableRangeDates';
import { UserService } from '../../_service/user/user.service';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { User } from '../../_model/bean/User';
import * as dayjs from 'dayjs';
import { StatisticsService } from '../../_service/statistics/statistics.service';
import { Subject } from 'rxjs';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-pain-point-chart',
  templateUrl: './pain-point-chart.component.html',
  styleUrls: ['./pain-point-chart.component.sass']
})
export class PainPointChartComponent implements OnInit, OnDestroy {
  @Input() type: 'primary' | 'all' | 'additional';
  @Input() title: string;
  data: any[] = [];
  data2: any[] = [];
  chartId = 'pain-point-chart';
  destroy$ = new Subject();
  params: HttpParams;
  heightOne: string;
  heightTwo: string;

  constructor(
    private readonly painPointService: PainPointService,
    private readonly userService: UserService,
    private readonly statisticsService: StatisticsService
  ) {
  }

  ngOnInit() {
    this.statisticsService.onDateChange
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: HttpParams) => {
        this.params = value;
        this.loadData();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private loadData() {
    if (this.type === 'primary') {
      this.painPointService.getPrimary(this.params).subscribe((data: PainPoint[]) => this.prepareData(data));
    } else if (this.type === 'additional') {
      this.painPointService.getAdditional(this.params).subscribe((data: PainPoint[]) => this.prepareData(data));
    } else if (this.type === 'all') {
      this.painPointService.getAll(this.params)
        .subscribe((data: PainPoint[]) => this.prepareData(data));
    }
  }

  private prepareData(data: any[]): void {
    const filterFn = item => item.value > 0;
    const unique = new Map();
    const newArr = [];

    data
      .filter((item: any) => item.count > 0)
      .map((item: any) => {
        return {
          name: item.clarification.title,
          value: item.count
        };
      })
      .forEach((item: any) => {
        if (!unique.has(item.name)) {
          newArr.push(item);
          unique.set(item.name, newArr.length - 1);
        } else {
          const index = unique.get(item.name);
          newArr[index].value = newArr[index].value + item.value;
        }
      });

    this.data = newArr;

    const getTitle = (item: any, id: string) => {
      if (Array.isArray(item.clarification.additionalClarifications)) {
        const object = item.clarification.additionalClarifications.find(add => `${add.id}` === `${id}`);
        if (object !== undefined) {
          return object.title;
        }
      }
      return '';
    };

    this.data2 = data
      .reduce((accum: any[], item: any) => {
        if (Array.isArray(item.additionalCountValue)) {
          const additionalMap = item.additionalCountValue.reduce((subAccum, subItem) => {
              const id = Object.keys(subItem)[0];
              return {
                ...subAccum,
                [id]: {
                  name: getTitle(item, id),
                  value: subAccum.hasOwnProperty(id) ? (subAccum[id].value + subItem[id]) : subItem[id]
                }
              };
            }, {});
          return [...accum, ...Object.values(additionalMap)];
        }
        return [...accum];
      }, [])
      .filter(filterFn);

    const count1 = this.getUniqueItemsCount(this.data);
    const count2 = this.getUniqueItemsCount(this.data2);
    this.heightOne = this.getHeight(count1, count1 > count2);
    this.heightTwo = this.getHeight(count2, count2 > count1);
  }

  getUniqueItemsCount(array: any[]): number {
    const obj = array.reduce((accum, item) => {
      accum[item.name] = true;
      return accum;
    }, {});
    return Object.keys(obj).length;
  }

  getHeight(count: number, lessThan: boolean): string {
    let value = (count  * (lessThan ? 53 : 60));
    value = value < 140 ? 140 : value;
    return value + 'px';
  }

  axisFormat(val) {
    if (val % 1 === 0) {
      return val.toLocaleString();
    } else {
      return '';
    }
  }
}
