import {Component} from '@angular/core';
import {LoginPageType} from '../../_model/LoginPageType';
import {AbstractPageComponent} from '../../_component/AbstractPageComponent';
import {loginAnimations} from './login.component.anim';
import {MatSnackBar} from '@angular/material';
import { AuthService, PASSWORD_KEY, USERNAME_KEY } from '../../_service/auth/auth.service';
import {NavigationService} from '../../_service/navigation/navigation.service';
import {UserService} from '../../_service/user/user.service';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  animations: loginAnimations,
  styleUrls: ['./login.component.sass']
})

export class LoginComponent extends AbstractPageComponent {
  public pageType: LoginPageType = LoginPageType.LOGIN;
  public username = '';
  public password = '';
  public isLoading = false;

  constructor(snackBar: MatSnackBar,
              authService: AuthService,
              userService: UserService,
              navService: NavigationService) {
    super(userService, snackBar, authService, navService);
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnInit(): void {
    super.ngOnInit();

    if (this.authService.isAuthenticated()) {
      this.navService.toDashboard();
    }

    if (!!localStorage.getItem(USERNAME_KEY)) {
      this.username = localStorage.getItem(USERNAME_KEY);
    }
    if (!!localStorage.getItem(PASSWORD_KEY)) {
      this.password = localStorage.getItem(PASSWORD_KEY);
    }
  }

  protected requireAuth(): boolean {
    return false;
  }

  public login() {
    this.isLoading = true;
    this.authService.login(this.username, this.password).subscribe(
      jwt => {
        this.authService.saveJwtToken(jwt.token);
        this.navService.toDashboard();

        localStorage.setItem(USERNAME_KEY, this.username);
        localStorage.setItem(PASSWORD_KEY, this.password);
      },
      (error: Error) => {
        this.showSnackBar('Неправильный логин или пароль');
        this.isLoading = false;
      },
      () => this.isLoading = false);
  }

  public togglePageType() {
    if (this.isCurrentPageHelp()) {
      this.pageType = LoginPageType.LOGIN;
    } else if (this.isCurrentPageLogin()) {
      this.pageType = LoginPageType.HELP;
    }
  }

  public isCurrentPageLogin(): boolean {
    return this.pageType === LoginPageType.LOGIN;
  }

  public isCurrentPageHelp(): boolean {
    return this.pageType === LoginPageType.HELP;
  }
}
