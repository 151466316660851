import {Injectable} from '@angular/core';
import {AbstractRestService} from '../abstract-rest.service';
import {HttpClient} from '@angular/common/http';
import {AuthTokenService} from '../auth/auth-token.service';
import {Observable} from 'rxjs';
import {Question} from '../../_model/bean/Question';
import {Branch} from '../../_model/bean/Branch';

@Injectable({
  providedIn: 'root'
})
export class QuestionService extends AbstractRestService {

  constructor(httpClient: HttpClient, tokenService: AuthTokenService) {
    super(httpClient, tokenService);
  }

  getByBranch(branch: Branch): Observable<Question[]> {
    return this.httpGet<Question[]>('company/branch/' + branch.id + '/question');
  }

  getAll(): Observable<Question[]> {
    return this.httpGet<Question[]>('company/question');
  }
}
