import {
  MAT_DATE_LOCALE,
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSnackBarModule,
  MatTableModule,
  MatToolbarModule
} from '@angular/material';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule} from '@angular/forms';
import {NgModule} from '@angular/core';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTooltipModule} from '@angular/material/tooltip';

export const modules = [
  MatButtonModule,
  BrowserAnimationsModule,
  MatCardModule,
  MatDividerModule,
  MatSnackBarModule,
  MatIconModule,
  MatProgressBarModule,
  MatToolbarModule,
  MatListModule,
  MatRippleModule,
  MatMenuModule,
  MatFormFieldModule,
  MatSelectModule,
  MatTableModule,
  FormsModule,
  MatSidenavModule,
  MatDialogModule,
  MatInputModule,
  MatPaginatorModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatCheckboxModule,
  MatProgressSpinnerModule,
  MatTooltipModule
];

@NgModule({
  declarations: [],
  imports: modules,
  exports: modules,
  providers: [{provide: MAT_DATE_LOCALE, useValue: 'ru-RU'}]
})
export class MaterialModule {
}
