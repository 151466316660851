import {FeedbackField} from './FeedbackField';

export class Feedback {
  id: number;
  date: number;
  visitorComment: string;
  visitorName: string;
  visitorPhone: string;
  branchId: number;
  clarificationId: number;
  questionId: number;
  terminalId: number;
  additionalClarificationId: number;
  campaignId: number;
  feedbackSourceId: number;
  companyId: number;
  rate: number;
  fields: FeedbackField[];
}
