import {Injectable} from '@angular/core';
import {AbstractRestService} from '../abstract-rest.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {AuthTokenService} from '../auth/auth-token.service';
import {StatisticsItem} from '../../_model/bean/StatisticsItem';
import { BehaviorSubject, Observable } from 'rxjs';
import {Branch} from '../../_model/bean/Branch';
import {Question} from '../../_model/bean/Question';
import {Terminal} from '../../_model/bean/Terminal';
import {Clarification} from '../../_model/bean/Clarification';
import * as dayjs from 'dayjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StatisticsService extends AbstractRestService {

  private dateChangeSubject = new BehaviorSubject<HttpParams>(null);

  get onDateChange(): Observable<HttpParams> {
    return this.dateChangeSubject.pipe(
      filter(value => !!value)
    );
  }

  constructor(httpClient: HttpClient, tokenService: AuthTokenService) {
    super(httpClient, tokenService);
  }

  getForCurrentCompany(
    companyId: number,
    dateAfter?: number,
    dateBefore?: number,
    branch?: Branch,
    question?: Question,
    terminal?: Terminal,
    clarification?: Clarification,
    additionalClarification?: Clarification
  ): Observable<StatisticsItem> {
    let params = new HttpParams();

    if (!dateAfter && !dateBefore) {
      const now = new Date();
      dateAfter = Date.UTC(now.getFullYear(), now.getMonth(), 1);
      dateBefore = now.getTime();
    }

    if (companyId != null) {
      params = params.set('company_id', companyId.toString());
    }

    if (branch != null) {
      params = params.set('branch_id', branch.id.toString());
    }

    if (dateAfter != null) {
      params = params.set('date_after', dateAfter.toString());
    }

    if (dateBefore != null) {
      params = params.set('date_before', dateBefore.toString());
    }

    if (question != null) {
      params = params.set('question_id', question.id.toString());
    }

    if (terminal != null) {
      params = params.set('terminal_id', terminal.id.toString());
    }

    if (clarification != null) {
      params = params.set('clarification_id', clarification.id.toString());
    }

    if (additionalClarification != null) {
      params = params.set('additional_clarification_id', additionalClarification.id.toString());
    }

    params = params.set('timezone', Intl.DateTimeFormat().resolvedOptions().timeZone);

    this.dateChangeSubject.next(params);

    return this.httpGet<StatisticsItem>('company/statistics', true, params);
  }

  getForToday(companyId: number): Observable<StatisticsItem> {
    const now = new Date();
    const after = dayjs().startOf('day').valueOf();
    const before = dayjs().endOf('day').valueOf();
    const dateAfter = after; // Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
    const dateBefore = before; // Date.UTC(now.getFullYear(), now.getMonth(), now.getDate() + 1);

    let params = new HttpParams()
      .set('date_before', dateBefore.toString())
      .set('date_after', dateAfter.toString());

    if (companyId != null) {
      params = params.set('company_id', companyId.toString());
    }

    params = params.set('timezone', Intl.DateTimeFormat().resolvedOptions().timeZone);

    return this.httpGet<StatisticsItem>('company/statistics', true, params);
  }
}
