import { AfterViewInit, Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import {AbstractPageComponent} from '../../_component/AbstractPageComponent';
import {MatPaginator, MatSnackBar, PageEvent} from '@angular/material';
import {AuthService} from '../../_service/auth/auth.service';
import {NavigationService} from '../../_service/navigation/navigation.service';
import {UserService} from '../../_service/user/user.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Feedback} from '../../_model/bean/Feedback';
import {FeedbackFilter} from '../../_model/FeedbackFilter';
import {FeedbackTableColumn} from '../../_model/FeedbackTableColumn';
import {FeedbackService} from '../../_service/feedback.service';
import {Branch} from '../../_model/bean/Branch';
import {Question} from '../../_model/bean/Question';
import {Terminal} from '../../_model/bean/Terminal';
import {Clarification} from '../../_model/bean/Clarification';
import {FeedbackTableRangeDates} from '../../_model/FeedbackTableRangeDates';
import {TerminalService} from '../../_service/company/terminal.service';
import {QuestionService} from '../../_service/company/question.service';
import {ClarificationService} from '../../_service/company/clarification.service';
import { ExportImportFormat, ExportService } from '../../_service/export/export.service';
import { DatePipe } from '@angular/common';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { User } from '../../_model/bean/User';
import * as dayjs from 'dayjs';
import { Observable } from 'rxjs';
import { FeedbackList } from '../../_model/bean/FeedbackList';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.sass'],
  providers: [DatePipe],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0', padding: 0})),
      state('expanded', style({height: '*', padding: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class FeedbackComponent extends AbstractPageComponent implements AfterViewInit {

  @ViewChild('tableWrapper', {static: true})
  tableWrapper: ElementRef;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  dataSource: Feedback[];
  terminals: Map<number, Terminal> = new Map();
  questions: Map<number, Question> = new Map();
  clarifications: Map<number, Clarification> = new Map();
  totalCount: number;
  isLoading = true;
  filter = new FeedbackFilter();
  expandedElement: FeedbackTableColumn | null;
  columns = [
    this.column('date', 'Дата'),
    this.column('rate', 'Оценка'),
    this.column('visitorComment', 'Комментарий'),
    this.column('visitorName', 'Имя'),
    this.column('visitorPhone', 'Телефон'),
    this.column('terminal', 'Терминал'),
    this.column('question', 'Вопрос'),
    this.column('clarification', 'Уточнение'),
    this.column('additionalClarification', 'Дополнительное уточнение'),
    this.column('fields', 'Поля'),
  ];
  displayedColumns = this.columns.map(value => value.id);
  height: string;

  constructor(private feedbackService: FeedbackService,
              private terminalService: TerminalService,
              private questionService: QuestionService,
              private clarificationService: ClarificationService,
              private exportService: ExportService,
              private datePipe: DatePipe,
              snackBar: MatSnackBar,
              userService: UserService,
              authService: AuthService,
              navService: NavigationService) {
    super(userService, snackBar, authService, navService);
  }

  ngAfterViewInit(): void {
    this.paginator._intl.itemsPerPageLabel = 'Кол-во на страницу: ';
    this.calcTableHeight(window.innerHeight - 130);
    this.loadFeedback();
    this.loadTerminals();
    this.loadQuestions();
    this.loadClarifications();
  }

  @HostListener('window:resize', ['$event.target'])
  onResize(target: Window) {
    this.calcTableHeight(target.innerHeight - 130);
  }

  calcTableHeight(height: number): void {
    if (this.height !== `${height}px` && height > 400 && this.tableWrapper) {
      this.height = `${height}px`;
      this.tableWrapper.nativeElement.style.height = this.height;
    }
  }

  async downloadXLSX() {
    const {feedback} = await this.getTableData(0, 1000).toPromise();

    const configFields: any[] = [
      {
        fieldName: 'dateLabel',
        label: 'Дата и время',
        type: 'string'
      },
      {
        fieldName: 'visitorComment',
        label: 'Комментарий',
        type: 'string'
      },
      {
        fieldName: 'visitorName',
        label: 'Имя',
        type: 'string'
      },
      {
        fieldName: 'visitorPhone',
        label: 'Телефон',
        type: 'string'
      },
      {
        fieldName: 'rateLabel',
        label: 'Оценка',
        type: 'string'
      },
      {
        fieldName: 'terminalLabel',
        label: 'Терминал',
        type: 'string'
      },
      {
        fieldName: 'questionLabel',
        label: 'Вопрос',
        type: 'string'
      },
      {
        fieldName: 'clarificationLabel',
        label: 'Уточнение',
        type: 'string'
      },
      {
        fieldName: 'additionalClarificationLabel',
        label: 'Дополнительное уточнение',
        type: 'string'
      }
    ];
    const header = configFields.map(f => f.label);
    const data = feedback.map((item: any) => {
      switch (item.rate) {
        case 1:
          item.rateLabel = 'Ужасно';
          break;
        case 2:
          item.rateLabel = 'Плохо';
          break;
        case 3:
          item.rateLabel = 'Нейтрально';
          break;
        case 4:
          item.rateLabel = 'Хорошо';
          break;
        case 5:
          item.rateLabel = 'Отлично';
          break;
      }
      if (item.date) {
        item.dateLabel = this.datePipe.transform(item.date, 'dd.MM.yy HH:mm');
      }
      if (item.terminalId) {
        item.terminalLabel = !!this.terminals[item.terminalId] ? this.terminals[item.terminalId].name : null;
      }
      if (item.questionId) {
        item.questionLabel = !!this.questions[item.questionId] ? this.questions[item.questionId].text : null;
      }
      if (item.clarificationId) {
        item.clarificationLabel = !!this.clarifications[item.clarificationId] ? this.clarifications[item.clarificationId].title : null;
      }
      if (item.additionalClarificationId) {
        item.additionalClarificationLabel = !!this.clarifications[item.additionalClarificationId]
          ? this.clarifications[item.additionalClarificationId].title
          : null;
      }
      return item;
    });
    this.exportService.export(data, configFields, ExportImportFormat.XLSX, header, 'Отзывы');
  }

  private loadClarifications() {
    this.clarificationService.getAll().subscribe(
      clarifications => clarifications.forEach(clarification =>
        this.clarifications[clarification.id] = clarification),
      error => this.onHttpError(error));
  }

  private loadQuestions() {
    this.questionService.getAll().subscribe(
      questions => questions.forEach(question => this.questions[question.id] = question),
      error => this.onHttpError(error));
  }

  private loadTerminals() {
    this.terminalService.getAll().subscribe(
      terminals => terminals.forEach(terminal => this.terminals[terminal.id] = terminal),
      error => this.onHttpError(error));
  }

  removeColumn(column: FeedbackTableColumn) {
    this.getColumnById(column.id).enabled = false;
    const index = this.displayedColumns.indexOf(column.id);
    if (index !== -1) {
      this.displayedColumns.splice(index, 1);
    }
  }

  addColumn(column: FeedbackTableColumn) {
    const internalColumn = this.getColumnById(column.id);
    internalColumn.enabled = true;
    this.displayedColumns.splice(this.columns.indexOf(internalColumn), 0, column.id);
  }

  getColumnById(id: string): FeedbackTableColumn | null {
    for (const column of this.columns) {
      if (column.id === id) {
        return column;
      }
    }
    return null;
  }

  loadFeedback(event?: PageEvent) {
    this.isLoading = true;
    let page: number;
    let limit: number;
    if (event != null) {
      page  = event.pageIndex;
      limit = event.pageSize;
    } else {
      page  = this.paginator.pageIndex;
      limit = this.paginator.pageSize;
    }

    this.getTableData(page, limit).subscribe(value => {
      this.dataSource = value.feedback;
      this.totalCount = value.totalCount;
      this.isLoading = false;
    }, error => this.onHttpError(error));
  }

  getTableData(page, limit): Observable<FeedbackList> {
    return this.userService.getUser()
      .pipe(
        map((user: User) => user.company.id),
        filter(companyId => !!companyId),
        take(1),
        switchMap(companyId => {
            return this.feedbackService.getForCurrentCompany(page, limit, companyId,
              this.filter.dateAfter, this.filter.dateBefore, this.filter.branch, this.filter.question, this.filter.terminal,
              this.filter.clarification, this.filter.additionalClarification,
            );
          }
        )
      );
  }

  private column(id: string, title: string): FeedbackTableColumn {
    return new FeedbackTableColumn(id, title);
  }

  onBranchSelected(branch: Branch) {
    this.filter.branch = branch;
    this.loadFeedback();
  }

  onQuestionSelected(question: Question) {
    this.filter.question = question;
    this.loadFeedback();
  }

  onTerminalSelected(terminal: Terminal) {
    this.filter.terminal = terminal;
    this.loadFeedback();
  }

  onClarificationSelected(clarification: Clarification) {
    this.filter.clarification = clarification;
    this.loadFeedback();
  }

  onAdditionalClarificationSelected(clarification: Clarification) {
    this.filter.additionalClarification = clarification;
    this.loadFeedback();
  }

  onDateRangeSelected(ranges: FeedbackTableRangeDates) {
    this.filter.dateBefore = ranges.dateBefore;
    this.filter.dateAfter = ranges.dateAfter;
    console.log('app-feedback', {
      dateBefore: dayjs(this.filter.dateBefore).format('DD.MM.YYYY HH:mm:ss'),
      dateAfter: dayjs(this.filter.dateAfter).format('DD.MM.YYYY HH:mm:ss')
    });
    this.loadFeedback();
  }

}
