import {Component} from '@angular/core';
import {AbstractPageComponent} from '../../_component/AbstractPageComponent';
import {MatSnackBar} from '@angular/material';
import {AuthService} from '../../_service/auth/auth.service';
import {NavigationService} from '../../_service/navigation/navigation.service';
import {StatisticsService} from '../../_service/statistics/statistics.service';
import {UserService} from '../../_service/user/user.service';
import {IndexesService} from '../../_service/statistics/indexes.service';
import {StatisticsRecord} from '../../_model/bean/StatisticsRecord';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { User } from '../../_model/bean/User';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.sass']
})
export class DashboardComponent extends AbstractPageComponent {

  public statisticsTotalItemCount = 0;
  public statisticsItemCount = 0;
  public statisticsNps = 0;
  public statisticsRecords: StatisticsRecord[];

  private isStatisticsLoading = true;
  public isLoading = true;

  constructor(private statisticsService: StatisticsService,
              private indexesService: IndexesService,
              snackBar: MatSnackBar,
              userService: UserService, authService: AuthService,
              navService: NavigationService) {
    super(userService, snackBar, authService, navService);
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnInit(): void {
    super.ngOnInit();

    this.userService.getUser()
      .pipe(
        map((user: User) => user.company.id),
        filter(companyId => !!companyId),
        take(1),
        switchMap((companyId) => this.statisticsService.getForToday(companyId))
      )
      .subscribe(
      data => {
        this.statisticsTotalItemCount = data.totalCount;
        this.statisticsItemCount = data.count;
        this.statisticsRecords = this.fillMissedDaysForStatisticsRecords(data.records);
        this.statisticsNps = data.nps;
      },
      error => this.onHttpError(error),
      () => {
        this.isStatisticsLoading = false;
        this.updateLoadingStatus();
      });
  }

  private fillMissedDaysForStatisticsRecords(records: StatisticsRecord[]): StatisticsRecord[] {
    const filledStatisticsRecords: StatisticsRecord[] = [];

    for (let i = 0; i < 24; i++) {
      const record = this.findRecordByTime(records, i);
      if (record) {
        filledStatisticsRecords.push(record);
      } else {
        const newRecord = new StatisticsRecord();
        newRecord.name = ('0' + i).slice(-2) + ':00';
        newRecord.series = [];
        filledStatisticsRecords.push(newRecord);
      }
    }

    return filledStatisticsRecords;
  }

  private findRecordByTime(records: StatisticsRecord[], hour: number): StatisticsRecord | null {
    const hourFormatted = ('0' + hour).slice(-2) + ':00';

    for (const record of records) {
      if (record.name === hourFormatted) {
        return record;
      }
    }

    return null;
  }

  private updateLoadingStatus() {
    this.isLoading = this.isStatisticsLoading;
  }
}
