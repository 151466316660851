import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import {FeedbackStatisticsEmojiItem} from '../../_model/FeedbackStatisticsEmojiItem';
import {StatisticsRecord} from '../../_model/bean/StatisticsRecord';
import {StatisticsSeriesItem} from '../../_model/bean/StatisticsSeriesItem';
import { AdvancedPieChartComponent } from '@swimlane/ngx-charts';

@Component({
  selector: 'app-feedback-statistics-emoji-grid',
  templateUrl: './feedback-statistics-emoji-grid.component.html',
  styleUrls: ['./feedback-statistics-emoji-grid.component.sass']
})
export class FeedbackStatisticsEmojiGridComponent implements OnInit {

  @ViewChild(AdvancedPieChartComponent, { static: true })
  chart: AdvancedPieChartComponent;

  @Input() itemFlex = 33;
  items: FeedbackStatisticsEmojiItem[];
  chartData: any[] = [
    {
      name: '1',
      value: 0,
    },
    {
      name: '2',
      value: 0,
    },
    {
      name: '3',
      value: 0,
    },
    {
      name: '4',
      value: 0,
    },
    {
      name: '5',
      value: 0,
    },
  ];
  colorScheme = {
    domain: [
      '#ef5350',
      '#ffa726',
      '#ffee58',
      '#9ccc65',
      '#66bb6a'
    ]
  };
  count = 0;
  goodCount = 0;
  badCount = 0;
  chartSvgWidth = 120;
  chartWidth = 550;
  chartHeight = 350;

  constructor() {
  }

  ngOnInit() {
    this.calcView();
  }

  get view(): [number, number] {
    return [this.chartWidth, this.chartHeight];
  }

  @Input()
  private set records(records: StatisticsRecord[]) {
    if (records === undefined) {
      records = [];
    }
    this.count = 0;

    const item1 = new FeedbackStatisticsEmojiItem();
    item1.color = '#ef5350';
    item1.icon = 'smile_1';
    const item2 = new FeedbackStatisticsEmojiItem();
    item2.color = '#ffa726';
    item2.icon = 'smile_2';
    const item3 = new FeedbackStatisticsEmojiItem();
    item3.color = '#ffee58';
    item3.icon = 'smile_3';
    const item4 = new FeedbackStatisticsEmojiItem();
    item4.color = '#9ccc65';
    item4.icon = 'smile_4';
    const item5 = new FeedbackStatisticsEmojiItem();
    item5.color = '#66bb6a';
    item5.icon = 'smile_5';

    for (const record of records) {
      item1.count += this.getCountBySeriesName(record.series, 'rate1');
      item2.count += this.getCountBySeriesName(record.series, 'rate2');
      item3.count += this.getCountBySeriesName(record.series, 'rate3');
      item4.count += this.getCountBySeriesName(record.series, 'rate4');
      item5.count += this.getCountBySeriesName(record.series, 'rate5');

      // this.badCount += item1.count;
      // this.badCount += item2.count;
      //
      // this.goodCount += item4.count;
      // this.goodCount += item5.count;
    }

    this.goodCount = [item5, item4].reduce((accum, item) => item.count + accum, 0);
    this.badCount = [item2, item1].reduce((accum, item) => item.count + accum, 0);
    this.count = [item5, item4, item3, item2, item1].reduce((accum, item) => item.count + accum, 0);

    item1.percent = this.escapeNan(Math.round((item1.count / this.count) * 100));
    item2.percent = this.escapeNan(Math.round((item2.count / this.count) * 100));
    item3.percent = this.escapeNan(Math.round((item3.count / this.count) * 100));
    item4.percent = this.escapeNan(Math.round((item4.count / this.count) * 100));
    item5.percent = this.escapeNan(Math.round((item5.count / this.count) * 100));

    this.chartData[0].value = item1.percent;
    this.chartData[1].value = item2.percent;
    this.chartData[2].value = item3.percent;
    this.chartData[3].value = item4.percent;
    this.chartData[4].value = item5.percent;
    this.setChartData(this.chartData);

    this.items = [item5, item4, item3, item2, item1];
  }

  private setChartData(value: any[]): void {
    setTimeout(() => {
      this.chart.results = value;
      this.chart.view = this.view;
      this.chart.update();
    });
  }

  private escapeNan(num: number): number {
    if (isNaN(num)) {
      return 0;
    } else {
      return num;
    }
  }

  private getCountBySeriesName(series: StatisticsSeriesItem[], name: string): number {
    for (const seriesItem of series) {
      if (seriesItem.name === name) {
        return seriesItem.value;
      }
    }

    return 0;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.calcView();
    this.chart.view = this.view;
    this.chart.update();
  }

  calcView(): void {
    if (window.innerWidth < 900) {
      this.chartWidth = 450;
      this.chartSvgWidth = 95;
    } else {
      this.chartWidth = 550;
      this.chartSvgWidth = 120;
    }
  }
}
