import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-feedback-count-card',
  templateUrl: './feedback-count-card.component.html',
  styleUrls: ['./feedback-count-card.component.sass']
})
export class FeedbackCountCardComponent implements OnInit {

  @Input() count = -1;
  @Input() title = 'Общее количество отзывов';

  constructor() {
  }

  ngOnInit() {
  }
}
