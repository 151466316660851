import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  $activeRouteChanged: Observable<string>;
  private activeRoute = '';

  constructor(private router: Router) {
    this.$activeRouteChanged = new Observable<string>(subscriber => {
      router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.activeRoute = event.urlAfterRedirects.substr(1, event.urlAfterRedirects.length);
          subscriber.next(this.activeRoute);
        }
      });
    });
  }

  toDashboard() {
    this.router.navigate(['statistics']);
  }

  isDashboard(): boolean {
    return this.activeRoute === 'dashboard';
  }

  toLogin() {
    this.router.navigate(['login']);
  }

  isLogin(): boolean {
    return this.activeRoute === 'login';
  }

  toFeedbackTable() {
    this.router.navigate(['feedback']);
  }

  isFeedbackTable(): boolean {
    return this.activeRoute === 'feedback';
  }

  toStatistics() {
    this.router.navigate(['statistics']);
  }

  isStatistics(): boolean {
    return this.activeRoute === 'statistics';
  }

  toBranchList() {
    this.router.navigate(['branches']);
  }

  isBranchList(): boolean {
    return this.activeRoute === 'branches';
  }

  toBranchDetails(branchId: number) {
    this.router.navigate(['/branches/' + branchId]);
  }

  toCompanyInfo() {
    this.router.navigate(['company']);
  }

  isCompanyInfo(): boolean {
    return this.activeRoute === 'company';
  }
}
