import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-nps-index-card',
  templateUrl: './nps-index-card.component.html',
  styleUrls: ['./nps-index-card.component.sass']
})
export class NpsIndexCardComponent implements OnInit {
  @Input()
  nps = -1;

  constructor() {
  }

  ngOnInit() {
  }
}
