import { Component, OnDestroy, OnInit } from '@angular/core';
import { FeedbackTableRangeDates } from '../../_model/FeedbackTableRangeDates';
import { HeatMapChartService } from '../../_service/statistics/heat-map-chart.service';
import { AbstractPageComponent } from '../AbstractPageComponent';
import { MatSnackBar } from '@angular/material';
import { UserService } from '../../_service/user/user.service';
import { AuthService } from '../../_service/auth/auth.service';
import { NavigationService } from '../../_service/navigation/navigation.service';
import { filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { User } from '../../_model/bean/User';
import * as dayjs from 'dayjs';
import { Subject } from 'rxjs';
import { StatisticsService } from '../../_service/statistics/statistics.service';
import { HttpParams } from '@angular/common/http';


@Component({
  selector: 'app-heat-map-chart',
  templateUrl: './heat-map-chart.component.html',
  styleUrls: ['./heat-map-chart.component.sass']
})
export class HeatMapChartComponent extends AbstractPageComponent implements OnInit, OnDestroy {

  filter: any = {};
  data: any;

  title = 'Температурная карта';
  chartId = 'charts-heat-map';

  // options
  legend = true;
  showLabels = true;
  animations = true;
  xAxis = true;
  yAxis = true;
  showYAxisLabel = true;
  showXAxisLabel = true;
  xAxisLabel = 'Время';
  yAxisLabel = 'Дни недели';
  params: HttpParams;

  colorScheme = {
    domain: [
      '#9e9e9e',
      '#ef5350',
      '#ffa726',
      '#ffee58',
      '#9ccc65',
      '#66bb6a',
    ]
  };

  destroy$ = new Subject();

  constructor(
    private readonly heatMapChartService: HeatMapChartService,
    snackBar: MatSnackBar,
    userService: UserService, authService: AuthService,
    navService: NavigationService,
    private readonly statisticsService: StatisticsService
  ) {
    super(userService, snackBar, authService, navService);
  }

  ngOnInit() {
    this.statisticsService.onDateChange
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: any) => {
        this.params = value;
        this.loadData();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  loadData() {
    this.heatMapChartService.getForCurrentCompany(this.params)
      .subscribe((data: any[]) => {
        let value = 0;
        this.data = data
          .map((hour: any, hourIndex: number) => {
          return {
            name: `${hour.name}:00`, // this.hours[hourIndex],
            series: hour.series.map((day: any, dayIndex: number) => {
              if (day.rates > value) {
                value =  Math.round(day.rates);
              }
              return { name: this.getDay(day.name), value: Math.round(day.rates) };
            }).reverse()
          };
        });
        // this.colorScheme.domain = this.colors.filter((color, index) => index <= value);
      });
  }

  onSelect(data): void {
    // console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    // console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    // console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

  getDay(day: string): string {
    switch (day) {
      case 'Mon':
        return 'Пн';
      case 'Tue':
        return 'Вт';
      case 'Wed':
        return 'Ср';
      case 'Thu':
        return 'Чт';
      case 'Fri':
        return 'Пт';
      case 'Sat':
        return 'Сб';
      case 'Sun':
        return 'Вс';
    }
  }
}


