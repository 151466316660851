import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Branch} from '../../../../_model/bean/Branch';
import {Question} from '../../../../_model/bean/Question';
import {Clarification} from '../../../../_model/bean/Clarification';
import {Observable} from 'rxjs';
import {ClarificationService} from '../../../../_service/company/clarification.service';

@Component({
  selector: 'app-feedback-table-filter-additional-clarification',
  templateUrl: './feedback-filter-additional-clarification.component.html'
})
export class FeedbackFilterAdditionalClarificationComponent implements OnInit {
// tslint:disable-next-line:variable-name
  private _branch: Branch | null;
  // tslint:disable-next-line:variable-name
  private _question: Question | null;
  // tslint:disable-next-line:variable-name
  private _clarification: Clarification | null;

  @Output() clarificationSelected = new EventEmitter<Clarification | null>();
  $clarifications: Observable<Clarification[]>;
  selectedClarification: Clarification | null;

  constructor(private clarificationService: ClarificationService) {
  }

  @Input()
  set branch(branch: Branch | null) {
    this._branch = branch;
    this.loadData();
  }

  @Input()
  set question(question: Question | null) {
    this._question = question;
    this.loadData();
  }

  @Input()
  set clarification(clarification: Clarification | null) {
    this._clarification = clarification;
    this.loadData();
  }

  get clarification(): Clarification | null {
    return this._clarification;
  }

  private loadData() {
    if (this._branch && this._question && this._clarification) {
      this.$clarifications = this.clarificationService.getAdditionalByBranchAndQuestion(this._branch, this._question, this._clarification);
    } else {
      this.$clarifications = null;
      this.selectedClarification = null;
    }
  }

  ngOnInit() {
  }

  selectClarification(clarification: Clarification) {
    this.selectedClarification = clarification;
    this.clarificationSelected.emit(clarification);
  }
}
