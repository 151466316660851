import {Injectable} from '@angular/core';
import {AbstractRestService} from '../abstract-rest.service';
import {HttpClient} from '@angular/common/http';
import {AuthTokenService} from '../auth/auth-token.service';
import {Observable} from 'rxjs';
import {StatisticsIndex} from '../../_model/bean/StatisticsIndex';

@Injectable({
  providedIn: 'root'
})
export class IndexesService extends AbstractRestService {

  constructor(httpClient: HttpClient, tokenService: AuthTokenService) {
    super(httpClient, tokenService);
  }

  get(): Observable<StatisticsIndex[]> {
    return this.httpGet<StatisticsIndex[]>('company/indexes');
  }
}
