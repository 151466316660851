import {Injectable} from '@angular/core';
import {AbstractRestService} from '../abstract-rest.service';
import {HttpClient} from '@angular/common/http';
import {AuthTokenService} from '../auth/auth-token.service';
import {CookieService} from 'ngx-cookie-service';
import {User} from '../../_model/bean/User';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService extends AbstractRestService {
  readonly KEY_COOKIE_USER = '_tMh4KiyAixXF7PAi';

  constructor(private cookieService: CookieService,
              httpClient: HttpClient,
              tokenService: AuthTokenService) {
    super(httpClient, tokenService);
  }

  public getUser(): Observable<User> {
    if (!this.cookieService.check(this.KEY_COOKIE_USER)) {
      return this.loadUserFromNetwork();
    } else {
      return this.loadUserFromCookies();
    }
  }

  public deleteUser() {
    this.cookieService.delete(this.KEY_COOKIE_USER);
  }

  private loadUserFromNetwork(): Observable<User> {
    const observable = this.httpGet<User>('user/me');
    observable.subscribe(user => this.saveUser(user));
    return observable;
  }

  private loadUserFromCookies(): Observable<User> {
    return new Observable(subscriber => subscriber.next(JSON.parse(this.cookieService.get(this.KEY_COOKIE_USER))));
  }

  public saveUser(user: User) {
    this.cookieService.set(this.KEY_COOKIE_USER, JSON.stringify(user));
  }
}
