import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FlexLayoutModule} from '@angular/flex-layout';
import {HttpClientModule} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';

export const modules = [
  CommonModule,
  BrowserModule,
  FlexLayoutModule,
  HttpClientModule,
];

@NgModule({
  declarations: [],
  imports: modules,
  exports: modules
})
export class BasicModule {
}
