import {Injectable} from '@angular/core';
import {AbstractRestService} from './abstract-rest.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {AuthTokenService} from './auth/auth-token.service';
import {Observable} from 'rxjs';
import {FeedbackList} from '../_model/bean/FeedbackList';
import {Branch} from '../_model/bean/Branch';
import {Question} from '../_model/bean/Question';
import {Terminal} from '../_model/bean/Terminal';
import {Clarification} from '../_model/bean/Clarification';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService extends AbstractRestService {

  constructor(httpClient: HttpClient, tokenService: AuthTokenService) {
    super(httpClient, tokenService);
  }

  getForCurrentCompany(page: number, limit: number,
                       companyId: number,
                       dateAfter?: number, dateBefore?: number,
                       branch?: Branch, question?: Question,
                       terminal?: Terminal, clarification?: Clarification,
                       additionalClarification?: Clarification): Observable<FeedbackList> {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('size', limit.toString());

    if (companyId != null) {
      params = params.set('company_id', companyId.toString());
    }

    if (branch != null) {
      params = params.set('branch_id', branch.id.toString());
    }

    if (dateAfter != null) {
      params = params.set('date_after', dateAfter.toString());
    }

    if (dateBefore != null) {
      params = params.set('date_before', dateBefore.toString());
    }

    if (question != null) {
      params = params.set('question_id', question.id.toString());
    }

    if (terminal != null) {
      params = params.set('terminal_id', terminal.id.toString());
    }

    if (clarification != null) {
      params = params.set('clarification_id', clarification.id.toString());
    }

    if (additionalClarification != null) {
      params = params.set('additional_clarification_id', additionalClarification.id.toString());
    }

    return this.httpGet<FeedbackList>('company/feedback', true, params);
  }
}
