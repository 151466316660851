import {Component, OnInit} from '@angular/core';
import {MatSnackBar} from '@angular/material';
import {AuthService} from '../../_service/auth/auth.service';
import {NavigationService} from '../../_service/navigation/navigation.service';
import {AbstractPageComponent} from '../../_component/AbstractPageComponent';
import {Observable} from 'rxjs';
import {Branch} from '../../_model/bean/Branch';
import {ColorLetters} from '../../_model/ColorLetters';
import {BranchService} from '../../_service/company/branch.service';
import {UserService} from '../../_service/user/user.service';

@Component({
  selector: 'app-branches',
  templateUrl: './branches.component.html',
  styleUrls: ['./branches.component.sass']
})
export class BranchesComponent extends AbstractPageComponent implements OnInit {

  isLoading = true;
  $branches: Observable<Branch[]>;
  colorLetters = new ColorLetters();

  constructor(private branchService: BranchService,
              userService: UserService,
              snackBar: MatSnackBar,
              authService: AuthService,
              navService: NavigationService) {
    super(userService, snackBar, authService, navService);
  }

  ngOnInit() {
    this.$branches = this.branchService.getAll();
    this.$branches.subscribe(() => this.isLoading = false, () => this.isLoading = false);
  }

  getColorByBranch(branch: Branch): string {
    return this.colorLetters.getFromBranch(branch);
  }
}
