import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import {UserService} from '../../_service/user/user.service';
import { Observable, Subject } from 'rxjs';
import {User} from '../../_model/bean/User';
import {NavigationService} from '../../_service/navigation/navigation.service';
import {AuthService} from '../../_service/auth/auth.service';
import { PdfSaverService } from '../../_service/export/pdf-saver.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.sass']
})
export class ToolbarComponent implements OnInit, OnDestroy {

  public $user: Observable<User> = this.userService.getUser();
  public activeRoute: string;
  isPdfAllow = true;
  destroy$ = new Subject();

  constructor(private userService: UserService,
              private authService: AuthService,
              protected cdr: ChangeDetectorRef,
              private pdfSaverService: PdfSaverService,
              public navigationService: NavigationService) {
    this.navigationService.$activeRouteChanged.subscribe(value => this.activeRoute = value);
  }

  public logout() {
    this.userService.deleteUser();
    this.authService.logout();
    this.navigationService.toLogin();
  }

  downloadPDF() {
    let chart;
    let pageName = 'Страница';
    if (this.navigationService.isDashboard()) {
      pageName = 'Главная';
      chart = document.querySelector('.app-dashboard');
    } else if (this.navigationService.isFeedbackTable()) {
      pageName = 'Отзывы';
      chart = document.querySelector('.feedback-table-container');
    } else if (this.navigationService.isStatistics()) {
      pageName = 'Статистика';
      chart = document.querySelector('app-statistics > div');
    } else if (this.navigationService.isCompanyInfo()) {
      pageName = 'Компания';
      chart = document.querySelector('.company-page');
    } else if (this.navigationService.isBranchList()) {
      pageName = 'Филиалы';
      chart = document.querySelector('.branches-card-item');
    }
    if (chart) {
      this.pdfSaverService.export(chart, pageName);
    }
  }

  ngOnInit() {
    this.navigationService.$activeRouteChanged
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.isPdfAllow = this.navigationService.isDashboard()
          || this.navigationService.isFeedbackTable()
          || this.navigationService.isStatistics();
        this.cdr.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
