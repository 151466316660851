import {Injectable} from '@angular/core';
import {AbstractRestService} from '../abstract-rest.service';
import {HttpClient} from '@angular/common/http';
import {AuthTokenService} from '../auth/auth-token.service';
import {Observable} from 'rxjs';
import {Branch} from '../../_model/bean/Branch';

@Injectable({
  providedIn: 'root'
})
export class BranchService extends AbstractRestService {

  constructor(httpClient: HttpClient, tokenService: AuthTokenService) {
    super(httpClient, tokenService);
  }

  getAll(): Observable<Branch[]> {
    return this.httpGet<Branch[]>('company/branch');
  }

  getById(id: number): Observable<Branch> {
    return this.httpGet<Branch>('company/branch/' + id);
  }
}
