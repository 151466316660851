import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {LoginComponent} from '../_page/login/login.component';
import {DashboardComponent} from '../_page/dashboard/dashboard.component';
import {FeedbackComponent} from '../_page/feedback/feedback.component';
import {BranchesComponent} from '../_page/branches/branches.component';
import {BranchDetailsComponent} from '../_page/branch-details/branch-details.component';
import {CompanyComponent} from '../_page/company/company.component';
import {IndexComponent} from '../_page/index/index.component';
import {StatisticsComponent} from '../_page/statistics/statistics.component';

@NgModule({
  imports: [RouterModule.forRoot([
    {path: '', component: IndexComponent},
    {path: 'login', component: LoginComponent},
    {path: 'dashboard', component: DashboardComponent},
    {path: 'feedback', component: FeedbackComponent},
    {path: 'statistics', component: StatisticsComponent},
    {path: 'company', component: CompanyComponent},
    {path: 'branches', component: BranchesComponent},
    {path: 'branches/:id', component: BranchDetailsComponent}
  ])],
  exports: [RouterModule]
})
export class RoutingModule {
}
