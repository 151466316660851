export class FeedbackTableColumn {
  id: string;
  title: string;
  enabled = true;

  constructor(id: string, title: string) {
    this.id = id;
    this.title = title;
  }
}
