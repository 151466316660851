import {Injectable} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class AuthTokenService {
  readonly KEY_COOKIE_AUTH_TOKEN = '_aDhhKl65ixJOxTil';

  constructor(private cookieService: CookieService) {
  }

  public getToken() {
    return this.cookieService.get(this.KEY_COOKIE_AUTH_TOKEN);
  }

  public deleteToken() {
    return this.cookieService.delete(this.KEY_COOKIE_AUTH_TOKEN);
  }

  public setToken(token: string) {
    return this.cookieService.set(this.KEY_COOKIE_AUTH_TOKEN, token);
  }

  public isTokenExists() {
    return this.getToken() != null && this.getToken() !== '';
  }
}
