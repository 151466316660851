import {Injectable} from '@angular/core';
import {AbstractRestService} from '../abstract-rest.service';
import {JwtToken} from '../../_model/bean/JwtToken';
import {HttpClient} from '@angular/common/http';
import {AuthTokenService} from './auth-token.service';
import {Observable} from 'rxjs';

export const PASSWORD_KEY = '__RATEME_PASSWORD__';
export const USERNAME_KEY = '__RATEME_USERNAME__';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends AbstractRestService {

  constructor(httpClient: HttpClient, tokenService: AuthTokenService) {
    super(httpClient, tokenService);
  }

  public login(username: string, pass: string): Observable<JwtToken> {
    return this.httpPost<JwtToken>(
      'login',
      {name: username, password: pass},
      false);
  }

  public logout() {
    this.tokenService.deleteToken();
    localStorage.removeItem(USERNAME_KEY);
    localStorage.removeItem(PASSWORD_KEY);
  }

  public saveJwtToken(token: string) {
    this.tokenService.setToken(token);
  }

  public isAuthenticated(): boolean {
    return this.tokenService.isTokenExists();
  }
}
