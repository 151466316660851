import { AfterViewInit, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FeedbackTableDateRangeItem } from '../../../_model/FeedbackTableDateRangeItem';
import { FeedbackTableRangeDates } from '../../../_model/FeedbackTableRangeDates';
import * as dayjs from 'dayjs';
import { FeedbackDateRangePickerService } from './feedback-date-range-picker.service';

const setToStorage   = (value) => localStorage.setItem('feedback_date_range', JSON.stringify(value));
const getFromStorage = (defaultValue) => !!localStorage.getItem('feedback_date_range')
  ? JSON.parse(localStorage.getItem('feedback_date_range'))
  : defaultValue;

@Component({
  selector   : 'app-feedback-table-date-range-picker',
  templateUrl: './feedback-date-range-picker.component.html',
  styleUrls  : ['./feedback-date-range-picker.component.sass']
})
export class FeedbackDateRangePickerComponent implements OnInit, AfterViewInit {

  @Output() rangeSelected       = new EventEmitter<FeedbackTableRangeDates>();
  @Output() customRangeSelected = new EventEmitter<FeedbackTableRangeDates>();

  ranges: FeedbackTableDateRangeItem[] = [
    // this.item('all', 'За все время'),
    this.item('day', 'Сегодня'),
    this.item('week', 'Последние 7 дней'),
    this.item('last_month', 'Прошлый месяц'),
    this.item('current_month', 'Текущий месяц'),
    this.item('year', 'Текущий год'),
  ];

  selectedRangeItem: FeedbackTableDateRangeItem = getFromStorage(this.ranges[1]);
  selectedDateRanges: FeedbackTableRangeDates|null;

  customDateRangeStart: number;
  customDateRangeEnd: number;

  constructor(private readonly feedbackDateRangePickerService: FeedbackDateRangePickerService) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.setupRanges();
  }

  selectItem(item: FeedbackTableDateRangeItem) {
    this.selectedRangeItem = item;
    setToStorage(item);
    this.setupRanges();
  }

  protected setupRanges() {
    const ranges = new FeedbackTableRangeDates();
    this.feedbackDateRangePickerService.rangeItemId.next(this.selectedRangeItem.id);

    switch (this.selectedRangeItem.id) {
      case 'last_month': {
        ranges.dateAfter  = dayjs().subtract(1, 'month').startOf('month').valueOf();
        ranges.dateBefore = dayjs().subtract(1, 'month').endOf('month').valueOf();
        break;
      }
      case 'current_month': {
        ranges.dateAfter  = dayjs().startOf('month').valueOf();
        ranges.dateBefore = dayjs().endOf('day').valueOf();
        break;
      }
      case 'current_year': {
        ranges.dateAfter  = dayjs().startOf('day').valueOf();
        ranges.dateBefore = dayjs().endOf('day').valueOf();
        break;
      }

      case 'day': {
        ranges.dateAfter  = dayjs().startOf('day').valueOf();
        ranges.dateBefore = dayjs().endOf('day').valueOf();
        break;
      }
      case 'week': {
        ranges.dateAfter  = dayjs().subtract(7, 'day').startOf('day').valueOf();
        ranges.dateBefore = dayjs().endOf('day').valueOf();
        break;
      }
      case 'month': {
        ranges.dateAfter  = dayjs().subtract(30, 'day').startOf('day').valueOf();
        ranges.dateBefore = dayjs().endOf('day').valueOf();
        break;
      }
      case 'year': {
        ranges.dateAfter  = dayjs().startOf('year').valueOf();
        ranges.dateBefore = dayjs().endOf('year').valueOf();
        break;
      }
      default : {
        ranges.dateAfter  = null;
        ranges.dateBefore = null;
      }
    }
    this.selectedDateRanges = ranges;
    this.rangeSelected.emit(ranges);
  }

  private item(id: string, title: string): FeedbackTableDateRangeItem {
    return new FeedbackTableDateRangeItem(id, title);
  }

  public onCustomYearSelected(year: string) {
    const ranges      = new FeedbackTableRangeDates();
    ranges.dateAfter  = dayjs(year).startOf('year').valueOf();
    ranges.dateBefore = dayjs(year).endOf('year').valueOf();
    this.rangeSelected.emit(ranges);
    this.selectedRangeItem = { id: null, title: null };
  }

  public applyCustomRange() {
    const ranges = new FeedbackTableRangeDates();

    ranges.dateAfter  = dayjs(this.customDateRangeStart).startOf('day').valueOf();
    ranges.dateBefore = dayjs(this.customDateRangeEnd).endOf('day').valueOf();

    this.selectedDateRanges = ranges;
    this.customRangeSelected.emit(ranges);
    this.rangeSelected.emit(ranges);
    this.selectedRangeItem = { id: null, title: null };
  }
}
