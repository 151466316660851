import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-rmi-index-card',
  templateUrl: './rmi-index-card.component.html',
  styleUrls: ['./rmi-index-card.component.sass']
})
export class RmiIndexCardComponent implements OnInit {

  @Input() rmi = -1;
  @Input() bestWorstDateSuffix: string;
  @Input() worstDate = '';
  @Input() bestDate = '';

  constructor() {
  }

  ngOnInit() {
  }
}
