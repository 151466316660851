import {Component, Input, OnInit} from '@angular/core';
import {StatisticsRecord} from '../../_model/bean/StatisticsRecord';

@Component({
  selector: 'app-feedback-statistics-chart',
  templateUrl: './feedback-statistics-chart.component.html',
  styleUrls: ['./feedback-statistics-chart.component.sass']
})
export class FeedbackStatisticsChartComponent implements OnInit {

  @Input() records: StatisticsRecord[] = [];
  @Input() title: string;

  chartId = 'statistic-chart';
  colors  = [
    '#ef5350',
    '#ffa726',
    '#ffee58',
    '#9ccc65',
    '#66bb6a',
  ];

  constructor() {
  }

  ngOnInit() {
  }

  public formatYAxisTick(value) {
    if (value % 1 === 0) {
      return value.toLocaleString();
    } else {
      return '';
    }
  }

  public formatDateForChart(dateString: string): string {
    const date = new Date(dateString);
    if (dateString.includes(':')) {
      return dateString;
    } else {
      return ('0' + date.getDate()).slice(-2) + '.'
        + ('0' + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear();
    }
  }
}
