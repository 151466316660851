import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Branch} from '../../../../_model/bean/Branch';
import {Observable} from 'rxjs';
import {Terminal} from '../../../../_model/bean/Terminal';
import {TerminalService} from '../../../../_service/company/terminal.service';

@Component({
  selector: 'app-feedback-table-filter-terminal',
  templateUrl: './feedback-filter-terminal.component.html'
})
export class FeedbackFilterTerminalComponent implements OnInit {

  @Output() terminalSelected = new EventEmitter<Terminal | null>();
  // tslint:disable-next-line:variable-name
  private _branch: Branch | null;

  $terminals: Observable<Terminal[]>;
  selectedTerminal: Terminal | null;

  constructor(
    private terminalService: TerminalService) {
  }

  @Input()
  set branch(branch: Branch | null) {
    this._branch = branch;
    if (branch != null) {
      this.$terminals = this.terminalService.getByBranch(branch);
    } else {
      this.$terminals = null;
      this.selectedTerminal = null;
    }
  }

  get branch(): Branch | null {
    return this._branch;
  }

  ngOnInit() {
  }

  selectTerminal(terminal: Terminal) {
    this.selectedTerminal = terminal;
    this.terminalSelected.emit(terminal);
  }
}
