import {Branch} from './bean/Branch';
import {Question} from './bean/Question';
import {Clarification} from './bean/Clarification';
import {Terminal} from './bean/Terminal';

export class FeedbackFilter {
  branch: Branch;
  question: Question;
  clarification: Clarification;
  terminal: Terminal;
  additionalClarification: Clarification;
  dateAfter: number | null;
  dateBefore: number | null;
  companyId: number | null;
}
