import {Component, OnInit} from '@angular/core';
import {AbstractPageComponent} from '../../_component/AbstractPageComponent';
import {MatSnackBar} from '@angular/material';
import {AuthService} from '../../_service/auth/auth.service';
import {NavigationService} from '../../_service/navigation/navigation.service';
import {UserService} from '../../_service/user/user.service';
import {Company} from '../../_model/bean/Company';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.sass']
})
export class CompanyComponent extends AbstractPageComponent implements OnInit {

  company: Company | null;

  constructor(userService: UserService,
              snackBar: MatSnackBar,
              authService: AuthService,
              navService: NavigationService) {
    super(userService, snackBar, authService, navService);
  }

  ngOnInit() {
    this.userService.getUser().subscribe(
      user => this.company = user.company,
      error => this.onHttpError(error));
  }
}
