import {Component, OnInit} from '@angular/core';
import { map, filter, take, switchMap } from 'rxjs/operators';
import {StatisticsRecord} from '../../_model/bean/StatisticsRecord';
import {AbstractPageComponent} from '../../_component/AbstractPageComponent';
import {StatisticsService} from '../../_service/statistics/statistics.service';
import {IndexesService} from '../../_service/statistics/indexes.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {UserService} from '../../_service/user/user.service';
import {AuthService} from '../../_service/auth/auth.service';
import {NavigationService} from '../../_service/navigation/navigation.service';
import {Branch} from '../../_model/bean/Branch';
import {Question} from '../../_model/bean/Question';
import {Terminal} from '../../_model/bean/Terminal';
import {Clarification} from '../../_model/bean/Clarification';
import {FeedbackTableRangeDates} from '../../_model/FeedbackTableRangeDates';
import {FeedbackFilter} from '../../_model/FeedbackFilter';
import {StatisticsScale} from './StatisticsScale';
import { User } from '../../_model/bean/User';
import { tap } from 'rxjs/internal/operators/tap';
import * as dayjs from 'dayjs';
import { PdfSaverService } from '../../_service/export/pdf-saver.service';
import { FeedbackDateRangePickerService } from '../../_component/feedback/feedback-date-range-picker/feedback-date-range-picker.service';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.sass']
})
export class StatisticsComponent extends AbstractPageComponent implements OnInit {

  private DAY_IN_MILLIS = 86400000;
  private WEEK_IN_MILLIS = 604800000;
  private MONTH_IN_MILLIS = 2592000000;
  private YEAR_IN_MILLIS = 31556952000;

  title = 'Количество отзывов';
  chartId = 'statistic-chart';

  public filledRecords: StatisticsRecord[];
  public records: StatisticsRecord[];
  public filter = new FeedbackFilter();
  public totalCount = 0;
  public count = 0;
  public nps = 0;
  public rmi = 0;
  public isLoading = true;
  public worstDate = '';
  public bestDate = '';
  public bestWorstDateSuffix = 'день';
  public scale: StatisticsScale;
  private customRangeSelected = false;

  constructor(private statisticsService: StatisticsService,
              private indexesService: IndexesService,
              private pdfSaverService: PdfSaverService,
              private feedbackDateRangePickerService: FeedbackDateRangePickerService,
              snackBar: MatSnackBar,
              userService: UserService, authService: AuthService,
              navService: NavigationService) {
    super(userService, snackBar, authService, navService);
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnInit() {
    super.ngOnInit();
  }

  downloadPDF() {
    const pageName = 'Статистика';
    const chart = document.querySelector('app-statistics > div');
    if (chart) {
      this.pdfSaverService.export(chart, pageName);
    }
  }

  public loadStatisticsData() {
    this.calculateScale();
    this.userService.getUser()
      .pipe(
        map((user: User) => user.company.id),
        filter(companyId => !!companyId),
        take(1),
        tap(companyId => {
          this.filter.companyId = companyId;
        }),
        switchMap(() =>
          this.statisticsService.getForCurrentCompany(
            this.filter.companyId,
            this.filter.dateAfter,
            this.filter.dateBefore,
            this.filter.branch,
            this.filter.question,
            this.filter.terminal,
            this.filter.clarification,
            this.filter.additionalClarification,
          )
        )
      )
    .subscribe(
      data => {
        this.totalCount = data.totalCount;
        this.count = data.count;
        this.filledRecords = this.fillMissedDatesForStatisticsRecords(data.records);
        this.records = data.records;
        this.nps = data.nps;
        this.rmi = data.rmi;
        this.bestDate = this.formatDateFromServer(data.bestDate);
        this.worstDate = this.formatDateFromServer(data.worstDate);
      },
      error => this.onHttpError(error),
      () => this.isLoading = false);
  }

  private calculateScale() {
    switch (this.feedbackDateRangePickerService.rangeItemId.value) {
      case 'day': {
        this.scale = StatisticsScale.DAY;
        break;
      }
      case 'week': {
        this.scale = StatisticsScale.WEEK;
        break;
      }
      case 'last_month': {
        this.scale = StatisticsScale.MONTH;
        break;
      }
      case 'current_month': {
        this.scale = StatisticsScale.CURRENT_MONTH;
        break;
      }
      case 'year': {
        this.scale = StatisticsScale.YEAR;
        break;
      }
      default: {
        this.scale = StatisticsScale.CUSTOM;
        break;
      }
    }
    // const datesDiff = this.filter.dateBefore - this.filter.dateAfter;

    /*if (this.customRangeSelected) {
      this.scale = StatisticsScale.CUSTOM;
      return;
    }

    if (datesDiff <= this.DAY_IN_MILLIS) {
      this.scale = StatisticsScale.DAY;
    } else if (datesDiff <= this.WEEK_IN_MILLIS) {
      this.scale = StatisticsScale.WEEK;
    } else if (datesDiff > this.WEEK_IN_MILLIS && datesDiff < this.YEAR_IN_MILLIS) {
      this.scale = StatisticsScale.MONTH;
    } else {
      this.scale = StatisticsScale.YEAR;
    }*/
  }

  public formatDateFromServer(dateString: string): string {
    const date = new Date(dateString);
    if (this.filter.dateBefore - this.filter.dateAfter <= this.DAY_IN_MILLIS) {
      this.bestWorstDateSuffix = 'час';
      return dateString;
    } else {
      this.bestWorstDateSuffix = 'день';
      if (dateString) {
        return ('0' + date.getDate()).slice(-2) + '.'
          + ('0' + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear();
      } else {
        return '-';
      }
    }
  }

  private fillMissedDatesForStatisticsRecords(records: StatisticsRecord[]): StatisticsRecord[] {
    const filledStatisticsRecords: StatisticsRecord[] = [];
    const dateBefore = new Date(this.filter.dateBefore);
    const dateAfter = new Date(this.filter.dateAfter);

    let loopRangeStart = 0;
    let loopRangeEnd = 0;

    switch (this.scale) {
      case StatisticsScale.DAY:
        loopRangeStart = 0;
        loopRangeEnd = 24;
        break;
      case StatisticsScale.WEEK:
        loopRangeStart = 0;
        loopRangeEnd = 7;
        break;
        case StatisticsScale.CURRENT_MONTH:
          loopRangeStart = 0;
          loopRangeEnd = dateBefore.getDate();
          break;
      case StatisticsScale.MONTH:
        loopRangeStart = 0;
        loopRangeEnd = 31;
        break;
      case StatisticsScale.YEAR:
        loopRangeStart = 0;
        loopRangeEnd = 12;
        break;
      case StatisticsScale.CUSTOM:
        loopRangeStart = dateAfter.getDate();
        loopRangeEnd = dateBefore.getDate();
        break;
    }

    for (let i = loopRangeStart; i < loopRangeEnd; i++) {
      let record: StatisticsRecord;
      let neededDate: Date;
      switch (this.scale) {
        case StatisticsScale.DAY:
          record = this.findRecordByTime(records, i);
          break;
        case StatisticsScale.WEEK:
          neededDate = new Date(dateAfter.getTime());
          neededDate.setDate(neededDate.getDate() + i);
          record = this.findRecordByDate(records, neededDate.getDate(),
            neededDate.getMonth() + 1, neededDate.getFullYear());
          break;
        case StatisticsScale.CURRENT_MONTH:
        case StatisticsScale.MONTH:
          neededDate = new Date(dateAfter.getTime());
          neededDate.setDate(neededDate.getDate() + i);
          record = this.findRecordByDate(records, neededDate.getDate(),
            neededDate.getMonth() + 1, neededDate.getFullYear());
          break;
        case StatisticsScale.YEAR:
          neededDate = new Date(dateAfter.getTime());
          neededDate.setMonth(neededDate.getMonth() + i);
          record = this.findRecordByYear(records, i + 1, neededDate.getFullYear());
          break;
        case StatisticsScale.CUSTOM:
          neededDate = new Date(dateAfter.getTime());
          neededDate.setDate(neededDate.getDate() + i);
          record = this.findRecordByDate(records, neededDate.getDate(),
            neededDate.getMonth() + 1, neededDate.getFullYear());
          break;
      }

      if (record) {
        filledStatisticsRecords.push(record);
      } else {
        const newRecord = new StatisticsRecord();
        switch (this.scale) {
          case StatisticsScale.DAY:
            newRecord.name = ('0' + i).slice(-2) + ':00';
            break;
          case StatisticsScale.WEEK:
            newRecord.name = neededDate.getFullYear()
              + '-' + ('0' + (neededDate.getMonth() + 1)).slice(-2)
              + '-' + ('0' + neededDate.getDate()).slice(-2);
            break;
          case StatisticsScale.CURRENT_MONTH:
          case StatisticsScale.MONTH:
            newRecord.name = neededDate.getFullYear()
              + '-' + ('0' + (neededDate.getMonth() + 1)).slice(-2)
              + '-' + ('0' + neededDate.getDate()).slice(-2);
            break;
          case StatisticsScale.YEAR:
            newRecord.name = neededDate.getFullYear()
              + '-' + ('0' + (i + 1)).slice(-2)
              + '-01';
            break;
          case StatisticsScale.CUSTOM:
            newRecord.name = neededDate.getFullYear()
              + '-' + ('0' + (neededDate.getMonth() + 1)).slice(-2)
              + '-' + ('0' + i).slice(-2);
            break;
        }
        newRecord.series = [];
        filledStatisticsRecords.push(newRecord);
      }
    }

    return filledStatisticsRecords;
  }

  private findRecordByYear(records: StatisticsRecord[], month: number, year: number): StatisticsRecord | null {
    const dateFormatted = year + '-' + ('0' + month).slice(-2);
    let parentRecord: StatisticsRecord = null;

    for (const record of records) {
      if (record.name.slice(0, 7) === dateFormatted) {
        if (!parentRecord) {
          parentRecord = {...record};
        } else {
          parentRecord.series = [...parentRecord.series, ...record.series];
        }
        // return record;
      }
    }

    if (parentRecord) {
      const seriesMap = parentRecord.series.reduce((accum, serie) => {
        if (!!accum[serie.name]) {
          return {...accum, [serie.name]: serie.value + accum[serie.name]};
        } else {
          return {...accum, [serie.name]: serie.value};
        }
      }, {});

      parentRecord.series = Object.keys(seriesMap)
        .map(name => {
          return {
            name,
            value: seriesMap[name]
          };
        })
        .filter(s => s.value > 0)
        .sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
    }

    return parentRecord;
  }

  private findRecordByDate(records: StatisticsRecord[], day: number, month: number, year: number): StatisticsRecord | null {
    const dateFormatted = year + '-' + ('0' + month).slice(-2) + '-' + ('0' + day).slice(-2);

    for (const record of records) {
      if (record.name === dateFormatted) {
        return record;
      }
    }

    return null;
  }

  private findRecordByTime(records: StatisticsRecord[], hour: number): StatisticsRecord | null {
    const hourFormatted = ('0' + hour).slice(-2) + ':00';

    for (const record of records) {
      if (record.name === hourFormatted) {
        return record;
      }
    }

    return null;
  }

  onBranchSelected(branch: Branch) {
    this.filter.branch = branch;
    this.loadStatisticsData();
  }

  onQuestionSelected(question: Question) {
    this.filter.question = question;
    this.loadStatisticsData();
  }

  onTerminalSelected(terminal: Terminal) {
    this.filter.terminal = terminal;
    this.loadStatisticsData();
  }

  onClarificationSelected(clarification: Clarification) {
    this.filter.clarification = clarification;
    this.loadStatisticsData();
  }

  onAdditionalClarificationSelected(clarification: Clarification) {
    this.filter.additionalClarification = clarification;
    this.loadStatisticsData();
  }

  onCustomDateRangeSelected(ranges: FeedbackTableRangeDates) {
    this.filter.dateBefore = ranges.dateBefore;
    this.filter.dateAfter = ranges.dateAfter;
    this.customRangeSelected = true;
    this.loadStatisticsData();
    console.log('app-statistics', {
      dateBefore: dayjs(this.filter.dateBefore).format('DD.MM.YYYY HH:mm:ss'),
      dateAfter: dayjs(this.filter.dateAfter).format('DD.MM.YYYY HH:mm:ss')
    });
  }

  onDateRangeSelected(ranges: FeedbackTableRangeDates) {
    this.filter.dateBefore = ranges.dateBefore;
    this.filter.dateAfter = ranges.dateAfter;
    this.customRangeSelected = false;
    this.loadStatisticsData();
    console.log('app-statistics', {
      dateBefore: dayjs(this.filter.dateBefore).format('DD.MM.YYYY HH:mm:ss'),
      dateAfter: dayjs(this.filter.dateAfter).format('DD.MM.YYYY HH:mm:ss')
    });
  }
}
