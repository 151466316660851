import {Branch} from './bean/Branch';

export class ColorLetters {
  private colorLetters: Map<string, string[]> = new Map();

  constructor() {
    this.colorLetters.set('А', ['#9C27B0', '#7B1FA2']);
    this.colorLetters.set('Б', ['#673AB7', '#512DA8']);
    this.colorLetters.set('В', ['#3F51B5', '#303F9F']);
    this.colorLetters.set('Г', ['#2196F3', '#1976D2']);
    this.colorLetters.set('Д', ['#03A9F4', '#0288D1']);
    this.colorLetters.set('Е', ['#00BCD4', '#0097A7']);
    this.colorLetters.set('Ё', ['#009688', '#00796B']);
    this.colorLetters.set('Ж', ['#4CAF50', '#388E3C']);
    this.colorLetters.set('З', ['#8BC34A', '#689F38']);
    this.colorLetters.set('И', ['#CDDC39', '#AFB42B']);
    this.colorLetters.set('Й', ['#FFEB3B', '#FBC02D']);
    this.colorLetters.set('К', ['#FFC107', '#FFA000']);
    this.colorLetters.set('Л', ['#FF9800', '#F57C00']);
    this.colorLetters.set('М', ['#FF5722', '#E64A19']);
    this.colorLetters.set('Н', ['#795548', '#5D4037']);
    this.colorLetters.set('О', ['#607D8B', '#455A64']);
    this.colorLetters.set('П', ['#9E9E9E', '#616161']);
    this.colorLetters.set('Р', ['#F44336', '#D32F2F']);
    this.colorLetters.set('С', ['#E91E63', '#C2185B']);
    this.colorLetters.set('Т', ['#F44336', '#D32F2F']);
    this.colorLetters.set('У', ['#E91E63', '#C2185B']);
    this.colorLetters.set('Ф', ['#3F51B5', '#303F9F']);
    this.colorLetters.set('Х', ['#2196F3', '#1976D2']);
    this.colorLetters.set('Ц', ['#03A9F4', '#0288D1']);
    this.colorLetters.set('Ч', ['#00BCD4', '#0097A7']);
    this.colorLetters.set('Ш', ['#009688', '#00796B']);
    this.colorLetters.set('Щ', ['#4CAF50', '#388E3C']);
    this.colorLetters.set('Ъ', ['#8BC34A', '#689F38']);
    this.colorLetters.set('Ы', ['#CDDC39', '#AFB42B']);
    this.colorLetters.set('Ь', ['#FFEB3B', '#FBC02D']);
    this.colorLetters.set('Э', ['#FFC107', '#FFA000']);
    this.colorLetters.set('Ю', ['#FF9800', '#F57C00']);
    this.colorLetters.set('Я', ['#FF5722', '#E64A19']);
  }

  get(letter: string): string {
    return this.colorLetters.get(letter)[0];
  }

  getFromBranch(branch: Branch): string {
    return this.colorLetters.get(branch.name.substr(0, 1).toUpperCase())[0];
  }

  getDarkFromBranch(branch: Branch): string {
    return this.colorLetters.get(branch.name.substr(0, 1).toUpperCase())[1];
  }
}
