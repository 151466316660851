import {animate, animateChild, group, query, state, style, transition, trigger, useAnimation} from '@angular/animations';
import {fadeIn} from 'ng-animate';

export const loginAnimations = [
  trigger('fade', [
    transition(':enter', [useAnimation(fadeIn, {params: {timing: 0.5, delay: 0.1}})]),
  ]),
  trigger('primary-panel-state', [
    state('left', style({order: 1, transform: 'translateX(0)'})),
    state('right', style({order: 2, transform: 'translateX(0)'})),

    transition('left => right', [
      group([
        animate('1000ms ease-in-out', style({transform: 'translateX(67%)'})),
        query('@fade', [animateChild()], {optional: true, delay: '500ms'})
      ])
    ]),
    transition('right => left', [
      group([
        animate('1000ms ease-in-out', style({transform: 'translateX(-67%)'})),
        query('@fade', [animateChild()], {optional: true, delay: '500ms'})
      ])
    ])
  ]),
  trigger('secondary-panel-state', [
    state('left', style({order: 1, transform: 'translateX(0)'})),
    state('right', style({order: 2, transform: 'translateX(0)'})),

    transition(':enter', [
      group([
        style({transform: 'translateX(100%)', 'min-width': '100%'}),
        animate('1000ms ease-in-out', style({transform: 'translateX(0)', 'min-width': '40%'})),
        query('@fade', [animateChild()], {optional: true, delay: '500ms'})
      ])
    ]),
    transition('left => right', [
      group([
        animate('1000ms ease-in-out', style({transform: 'translateX(150%)'})),
        query('@fade', [animateChild()], {optional: true, delay: '500ms'}),
      ])
    ]),
    transition('right => left', [
      group([
        animate('1000ms ease-in-out', style({transform: 'translateX(-150%)'})),
        query('@fade', [animateChild()], {optional: true, delay: '500ms'})
      ])
    ])
  ])
];
