import {RoutingModule} from './_module/routing.module';
import {AppComponent} from './app.component';
import {LoginComponent} from './_page/login/login.component';
import {Injectable, NgModule} from '@angular/core';
import {MaterialModule} from './_module/material.module';
import {BasicModule} from './_module/basic.module';
import {AuthService} from './_service/auth/auth.service';
import {AuthTokenService} from './_service/auth/auth-token.service';
import {CookieService} from 'ngx-cookie-service';
import {DashboardComponent} from './_page/dashboard/dashboard.component';
import {NavigationService} from './_service/navigation/navigation.service';
import {StatisticsService} from './_service/statistics/statistics.service';
import { BarChartModule, HeatMapModule, PieChartModule } from '@swimlane/ngx-charts';
import {FeedbackStatisticsChartComponent} from './_component/feedback-statistics-chart/feedback-statistics-chart.component';
import {ToolbarComponent} from './_component/toolbar/toolbar.component';
import {UserService} from './_service/user/user.service';
import {PainPointChartComponent} from './_component/pain-point-chart/pain-point-chart.component';
import {NpsIndexCardComponent} from './_component/nps-index-card/nps-index-card.component';
import {RmiIndexCardComponent} from './_component/rmi-index-card/rmi-index-card.component';
import {FeedbackCountCardComponent} from './_component/feedback-count-card/feedback-count-card.component';
import {PainPointService} from './_service/statistics/pain-point.service';
import {StatisticsComponent} from './_page/statistics/statistics.component';
import {FeedbackComponent} from './_page/feedback/feedback.component';
import {FeedbackService} from './_service/feedback.service';
import {BranchService} from './_service/company/branch.service';
import {FeedbackFilterBranchComponent} from './_component/feedback/filter/feedback-filter-branch/feedback-filter-branch.component';
import {QuestionService} from './_service/company/question.service';
import {FeedbackFilterQuestionComponent} from './_component/feedback/filter/feedback-filter-question/feedback-filter-question.component';
import {FeedbackFilterClarificationComponent} from './_component/feedback/filter/feedback-filter-clarification/feedback-filter-clarification.component';
import {ClarificationService} from './_service/company/clarification.service';
import {FeedbackFilterAdditionalClarificationComponent} from './_component/feedback/filter/feedback-filter-additional-clarification/feedback-filter-additional-clarification.component';
import {FeedbackTableSettingsComponent} from './_component/feedback/feedback-table-settings/feedback-table-settings.component';
import {FeedbackDateRangePickerComponent} from './_component/feedback/feedback-date-range-picker/feedback-date-range-picker.component';
import {FeedbackFilterTerminalComponent} from './_component/feedback/filter/feedback-filter-terminal/feedback-filter-terminal.component';
import {BranchesComponent} from './_page/branches/branches.component';
import {BranchDetailsComponent} from './_page/branch-details/branch-details.component';
import {CompanyComponent} from './_page/company/company.component';
import {IndexesService} from './_service/statistics/indexes.service';
import {IndexComponent} from './_page/index/index.component';
import { FeedbackStatisticsEmojiGridComponent } from './_component/feedback-statistics-emoji-grid/feedback-statistics-emoji-grid.component';
import { HeatMapChartComponent } from './_component/heat-map-chart/heat-map-chart.component';
import { CustomIconRegistry, SVG_ICONS } from './_service/icon/custom-icon-registry';
import { MatIconRegistry } from '@angular/material';
import {
  FeedbackTableColumnFieldsComponent
} from './_component/feedback/feedback-table-column/fields/feedback-table-column-fields.component';

// These are the hardcoded inline svg sources to be used by the `<mat-icon>` component.
// tslint:disable: max-line-length
export const svgIconProviders = [
  {
    provide: SVG_ICONS,
    useValue: {
      name: 'smile_1',
      svgSource:
        `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve" width="512px" height="512px" class=""><g><circle style="fill:#E82525" cx="256" cy="256" r="256" data-original="#FFD93B" class="" data-old_color="#FFD93B"/><path style="fill:#D22121" d="M512,256c0,141.44-114.64,256-256,256c-80.48,0-152.32-37.12-199.28-95.28  c43.92,35.52,99.84,56.72,160.72,56.72c141.36,0,256-114.56,256-256c0-60.88-21.2-116.8-56.72-160.72  C474.8,103.68,512,175.52,512,256z" data-original="#F4C534" class="active-path" data-old_color="#F4C534"/><g>
<path style="fill:#3E4347;" d="M354.592,407.184c-12.736,7.68-47.008-26.496-94.24-26.912   c-46.544-0.416-81.504,34.608-94.24,26.912c-15.248-6.208,10.832-80.608,94.24-81.056   C343.76,326.576,369.84,400.992,354.592,407.184z" data-original="#3E4347"/>
<path style="fill:#3E4347;" d="M390.336,221.52c0,22.544-18.448,40.992-41.088,40.992c-22.656,0-41.088-18.448-41.088-40.992   c0-22.752,18.448-41.088,41.088-41.088C371.904,180.432,390.336,198.768,390.336,221.52z" data-original="#3E4347"/>
</g><ellipse transform="matrix(-0.7551 -0.6556 0.6556 -0.7551 503.256 596.3099)" style="fill:#5A5F63" cx="363.001" cy="204.161" rx="12.112" ry="7.712" data-original="#5A5F63" class="" data-old_color="#5A5F63"/><path style="fill:#3E4347;" d="M203.84,221.52c0,22.544-18.448,40.992-41.088,40.992s-41.088-18.448-41.088-40.992  c0-22.752,18.448-41.088,41.088-41.088C185.392,180.432,203.84,198.768,203.84,221.52z" data-original="#3E4347"/><ellipse transform="matrix(-0.7551 -0.6556 0.6556 -0.7551 175.8983 474.0588)" style="fill:#5A5F63" cx="176.489" cy="204.177" rx="12.112" ry="7.712" data-original="#5A5F63" class="" data-old_color="#5A5F63"/></g> </svg>`,
    },
    multi: true,
  },
  {
    provide: SVG_ICONS,
    useValue: {
      name: 'smile_2',
      svgSource:
        `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve" width="512px" height="512px" class=""><g><circle style="fill:#FFB229" cx="256" cy="256" r="256" data-original="#FFD93B" class="" data-old_color="#FFD93B"/><path style="fill:#E39D20" d="M512,256c0,141.44-114.64,256-256,256c-80.48,0-152.32-37.12-199.28-95.28  c43.92,35.52,99.84,56.72,160.72,56.72c141.36,0,256-114.56,256-256c0-60.88-21.2-116.8-56.72-160.72  C474.8,103.68,512,175.52,512,256z" data-original="#F4C534" class="active-path" data-old_color="#F4C534"/><g>
<path style="fill:#3E4347;" d="M170.864,404.24c-7.344-4.912-9.312-14.848-4.4-22.192c37.056-55.36,94.24-55.36,173.392-55.36   c8.832,0,16,7.168,16,16s-7.168,16-16,16c-80.528,0-120.416,1.744-146.8,41.168C188.112,407.216,178.176,409.12,170.864,404.24z" data-original="#3E4347"/>
<circle style="fill:#3E4347;" cx="163.2" cy="213.712" r="41.056" data-original="#3E4347"/>
</g><path style="fill:#5A5F63" d="M186.416,206.576c-2.848,2.688-8.224,1.712-11.664-2.112c-3.552-3.808-4.16-9.216-1.168-11.824  c2.96-2.72,8.208-1.824,11.76,2C188.912,198.56,189.376,203.872,186.416,206.576z" data-original="#5A5F63" class="" data-old_color="#5A5F63"/><circle style="fill:#3E4347;" cx="348.864" cy="213.712" r="41.056" data-original="#3E4347"/><path style="fill:#5A5F63" d="M372.08,206.576c-2.848,2.688-8.224,1.712-11.664-2.112c-3.552-3.808-4.16-9.216-1.168-11.824  c2.96-2.72,8.208-1.824,11.76,2C374.576,198.56,375.04,203.872,372.08,206.576z" data-original="#5A5F63" class="" data-old_color="#5A5F63"/></g> </svg>`,
    },
    multi: true,
  },
  {
    provide: SVG_ICONS,
    useValue: {
      name: 'smile_3',
      svgSource:
        `<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
 viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
<circle style="fill:#FFD93B;" cx="256" cy="256" r="256"/>
<path style="fill:#F4C534;" d="M512,256c0,141.44-114.64,256-256,256c-80.48,0-152.32-37.12-199.28-95.28
c43.92,35.52,99.84,56.72,160.72,56.72c141.36,0,256-114.56,256-256c0-60.88-21.2-116.8-56.72-160.72
C474.8,103.68,512,175.52,512,256z"/>
<g>
<path style="fill:#3E4347;" d="M370.56,344.4c0,7.696-6.224,13.92-13.92,13.92H155.344c-7.616,0-13.92-6.224-13.92-13.92
s6.304-13.92,13.92-13.92H356.64C364.352,330.496,370.56,336.704,370.56,344.4z"/>
<circle style="fill:#3E4347;" cx="174.512" cy="207.088" r="38.144"/>
</g>
<ellipse transform="matrix(-0.597 -0.8023 0.8023 -0.597 150.8425 458.814)" style="fill:#5A5F63;" cx="190.667" cy="191.518" rx="10.016" ry="6.48"/>
<circle style="fill:#3E4347;" cx="337.44" cy="207.088" r="38.144"/>
<ellipse transform="matrix(-0.597 -0.8022 0.8022 -0.597 411.0304 589.5384)" style="fill:#5A5F63;" cx="353.586" cy="191.533" rx="10.016" ry="6.48"/>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>`,
    },
    multi: true,
  },
  {
    provide: SVG_ICONS,
    useValue: {
      name: 'smile_4',
      svgSource:
        `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve" width="512px" height="512px" class=""><g><g><circle style="fill:#28E64C" cx="256" cy="256" r="256" data-original="#28E64C" class="" data-old_color="#FFD93B"/><path style="fill:#20CA40" d="M512,256c0,141.44-114.64,256-256,256c-80.48,0-152.32-37.12-199.28-95.28  c43.92,35.52,99.84,56.72,160.72,56.72c141.36,0,256-114.56,256-256c0-60.88-21.2-116.8-56.72-160.72  C474.8,103.68,512,175.52,512,256z" data-original="#20CA40" class="" data-old_color="#F4C534"/><circle style="fill:#3E4347;" cx="350.176" cy="225.6" r="40.176" data-original="#3E4347" class=""/><ellipse transform="matrix(-0.9141 -0.4054 0.4054 -0.9141 607.8411 544.3153)" style="fill:#5A5F63" cx="361.564" cy="207.787" rx="12.16" ry="7.872" data-original="#5A5F63" class="active-path" data-old_color="#5A5F63"/><circle style="fill:#3E4347;" cx="161.824" cy="225.6" r="40.176" data-original="#3E4347" class=""/><ellipse transform="matrix(-0.9141 -0.4054 0.4054 -0.9141 247.3004 467.9457)" style="fill:#5A5F63" cx="173.206" cy="207.784" rx="12.16" ry="7.872" data-original="#5A5F63" class="active-path" data-old_color="#5A5F63"/><path style="fill:#3E4347;" d="M151.136,357.056c-4.832-4.544-5.056-12.144-0.512-16.96c4.544-4.816,12.128-5.056,16.96-0.512  c49.584,46.672,127.248,46.672,176.832,0c4.832-4.544,12.416-4.304,16.96,0.512c4.544,4.832,4.32,12.416-0.512,16.96  C302.064,412.416,209.936,412.416,151.136,357.056z" data-original="#3E4347" class=""/></g></g> </svg>`,
    },
    multi: true,
  },
  {
    provide: SVG_ICONS,
    useValue: {
      name: 'smile_5',
      svgSource:
        `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve" width="512px" height="512px" class=""><g><g><circle style="fill:#11AF04" cx="256" cy="256" r="256" data-original="#11AF04" class="" data-old_color="#FFD93B"/><path style="fill:#25292C" d="M256,420.544c-74.864,0-140.096-50.704-158.624-123.28c-1.456-5.712,1.984-11.52,7.696-12.976  c5.68-1.488,11.504,1.984,12.96,7.696c16.128,63.136,72.848,107.232,137.952,107.232s121.84-44.096,137.952-107.232  c1.44-5.712,7.248-9.184,12.976-7.696c5.712,1.456,9.152,7.264,7.696,12.976C396.096,369.84,330.864,420.544,256,420.544z" data-original="#25292C" class="" data-old_color="#3E4347"/><path style="fill:#269100" d="M512,256c0,141.44-114.64,256-256,256c-80.48,0-152.32-37.12-199.28-95.28  c43.92,35.52,99.84,56.72,160.72,56.72c141.36,0,256-114.56,256-256c0-60.88-21.2-116.8-56.72-160.72  C474.8,103.68,512,175.52,512,256z" data-original="#269100" class="" data-old_color="#F4C534"/><ellipse style="fill:#25292C" cx="173.328" cy="220.304" rx="39.232" ry="46.624" data-original="#25292C" class="" data-old_color="#3E4347"/><path style="fill:#565A5E" d="M191.024,210.272c-3.056,2.4-8.08,1.216-11.296-2.688s-3.376-9.136-0.32-11.536  c3.04-2.512,8.08-1.328,11.28,2.576C193.92,202.64,193.952,207.776,191.024,210.272z" data-original="#565A5E" class="active-path" data-old_color="#5A5F63"/><ellipse style="fill:#25292C" cx="338.72" cy="220.304" rx="39.232" ry="46.624" data-original="#25292C" class="" data-old_color="#3E4347"/><path style="fill:#565A5E" d="M356.384,210.272c-3.056,2.4-8.08,1.216-11.296-2.688s-3.376-9.136-0.32-11.536  c3.04-2.512,8.064-1.328,11.28,2.576C359.28,202.64,359.312,207.776,356.384,210.272z" data-original="#565A5E" class="active-path" data-old_color="#5A5F63"/></g></g> </svg>`,
    },
    multi: true,
  },
];
// tslint:enable: max-line-length

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    FeedbackStatisticsChartComponent,
    ToolbarComponent,
    PainPointChartComponent,
    NpsIndexCardComponent,
    RmiIndexCardComponent,
    FeedbackCountCardComponent,
    StatisticsComponent,
    FeedbackComponent,
    FeedbackFilterBranchComponent,
    FeedbackFilterQuestionComponent,
    FeedbackFilterClarificationComponent,
    FeedbackFilterAdditionalClarificationComponent,
    FeedbackTableSettingsComponent,
    FeedbackDateRangePickerComponent,
    FeedbackFilterTerminalComponent,
    BranchesComponent,
    BranchDetailsComponent,
    CompanyComponent,
    IndexComponent,
    FeedbackStatisticsEmojiGridComponent,
    HeatMapChartComponent,
    FeedbackTableColumnFieldsComponent
  ],
  imports: [
    BasicModule,
    RoutingModule,
    MaterialModule,
    BarChartModule,
    PieChartModule,
    HeatMapModule,
  ],
  providers: [
    AuthService,
    AuthTokenService,
    CookieService,
    NavigationService,
    StatisticsService,
    UserService,
    PainPointService,
    FeedbackService,
    BranchService,
    QuestionService,
    ClarificationService,
    IndexesService,
    { provide: MatIconRegistry, useClass: CustomIconRegistry },
    svgIconProviders,
  ],
  bootstrap: [AppComponent]
})
@Injectable()
export class AppModule {
}
