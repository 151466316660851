import {AbstractRestService} from '../abstract-rest.service';
import {Injectable} from '@angular/core';
import {AuthTokenService} from '../auth/auth-token.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {PainPoint} from '../../_model/bean/PainPoint';

@Injectable({
  providedIn: 'root'
})
export class PainPointService extends AbstractRestService {

  constructor(httpClient: HttpClient, tokenService: AuthTokenService) {
    super(httpClient, tokenService);
  }

  getPrimary(httpParams: HttpParams): Observable<PainPoint[]> {
    return this.httpGet<PainPoint[]>('company/pain_point/primary', true, httpParams);
  }

  getAdditional(httpParams: HttpParams): Observable<PainPoint[]> {
    return this.httpGet<PainPoint[]>('company/pain_point/additional', true, httpParams);
  }

  getAll(httpParams: HttpParams): Observable<PainPoint[]> {
    return this.httpGet<PainPoint[]>('company/pain_point', true, httpParams);
  }
}
