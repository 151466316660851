import {Injectable} from '@angular/core';
import {AbstractRestService} from '../abstract-rest.service';
import {HttpClient} from '@angular/common/http';
import {AuthTokenService} from '../auth/auth-token.service';
import {Observable} from 'rxjs';
import {Clarification} from '../../_model/bean/Clarification';
import {Question} from '../../_model/bean/Question';
import {Branch} from '../../_model/bean/Branch';

@Injectable({
  providedIn: 'root'
})
export class ClarificationService extends AbstractRestService {

  constructor(httpClient: HttpClient, tokenService: AuthTokenService) {
    super(httpClient, tokenService);
  }

  getAll(): Observable<Clarification[]> {
    return this.httpGet<Clarification[]>('company/clarification');
  }

  getByBranchAndQuestion(branch: Branch, question: Question): Observable<Clarification[]> {
    return this.httpGet<Clarification[]>('company/branch/' + branch.id + '/question/' + question.id + '/clarification');
  }

  getAdditionalByBranchAndQuestion(branch: Branch,
                                   question: Question,
                                   clarification: Clarification): Observable<Clarification[]> {
    return this.httpGet<Clarification[]>('company/branch/' + branch.id + '/question/' + question.id + '/clarification/' +
      clarification.id + '/additional');
  }
}
