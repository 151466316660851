import {Injectable} from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {Observable, of} from 'rxjs';
import { AbstractRestService } from '../abstract-rest.service';
import { AuthTokenService } from '../auth/auth-token.service';
import { StatisticsItem } from '../../_model/bean/StatisticsItem';

@Injectable({
  providedIn: 'root'
})
export class HeatMapChartService extends AbstractRestService {
  constructor(httpClient: HttpClient, tokenService: AuthTokenService) {
    super(httpClient, tokenService);
  }

  getForCurrentCompany(
    params: HttpParams
  ): Observable<any> {
    return this.httpGet<StatisticsItem>('company/statistics/week', true, params);
  }
}
