import {Component} from '@angular/core';
import {AbstractPageComponent} from '../../_component/AbstractPageComponent';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AuthService} from '../../_service/auth/auth.service';
import {UserService} from '../../_service/user/user.service';
import {NavigationService} from '../../_service/navigation/navigation.service';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.sass']
})
export class IndexComponent extends AbstractPageComponent {

  constructor(private navigationService: NavigationService,
              snackBar: MatSnackBar,
              authService: AuthService,
              userService: UserService,
              navService: NavigationService) {
    super(userService, snackBar, authService, navService);

    if (authService.isAuthenticated()) {
      this.navigationService.toDashboard();
    } else {
      this.navigationService.toLogin();
    }
  }
}
