import { Injectable, NgZone } from '@angular/core';
import html2canvas from 'html2canvas';

declare var pdfMake: any;

@Injectable({
  providedIn: 'root'
})
export class PdfSaverService {
  constructor(private readonly zone: NgZone) {}

  export(selector: any, name: string) {
    return this.zone.runOutsideAngular(() => this.process(selector, name));
  }

  private process(chart, name) {
    return new Promise(resolve => {
      html2canvas(chart, {
        logging        : false,
      }).then((canvas) => {
        // Get chart data so we can append to the pdf
        const chartData     = canvas.toDataURL();
        // Prepare pdf structure
        const margin = 20;
        const dd = {
          pageSize: { width: chart.offsetWidth, height: chart.offsetHeight},
          pageMargins: [margin, margin, margin, margin],
          content: [
            {
              image: chartData,
              width: chart.offsetWidth - (margin * 2),
              height: chart.offsetHeight - (margin * 2)
            }
          ]
        };
        pdfMake.createPdf(dd).download(name + '.pdf');

        resolve();
      });
    });
  }
}
