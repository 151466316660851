import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {AuthTokenService} from './auth/auth-token.service';
import {DataResponse} from '../_model/bean/DataResponse';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

export abstract class AbstractRestService {

  protected constructor(private httpClient: HttpClient, protected tokenService: AuthTokenService) {
  }

  private static getEndpointUrl(endpoint: string) {
    if (!environment.API_URL.endsWith('/')) {
      throw new DOMException('Base REST API url must ends with slash');
    }
    return environment.API_URL + endpoint;
  }

  protected httpGet<T>(endpoint: string, requireAuth = true, httpParams: HttpParams = null): Observable<T> {
    return this.doHttpWork(this.httpClient.get<DataResponse<T>>(AbstractRestService.getEndpointUrl(endpoint), {
      headers: this.getHeaders(requireAuth),
      params: httpParams
    }));
  }

  protected httpPost<T>(endpoint: string, body: any, requireAuth = true, httpParams: HttpParams = null): Observable<T> {
    return this.doHttpWork(this.httpClient.post<DataResponse<T>>(AbstractRestService.getEndpointUrl(endpoint), body, {
      headers: this.getHeaders(requireAuth),
      params: httpParams
    }));
  }

  private doHttpWork<T>(observable: Observable<DataResponse<T>>): Observable<T> {
    return observable
      .pipe(map<DataResponse<T>, T>(dataResponse => {
          if (dataResponse.error || dataResponse.data == null) {
            throw new Error(dataResponse.message);
          }
          return dataResponse.data;
        }
      ))
      .pipe(catchError(err => throwError(err)));
  }

  private getHeaders(requireAuth: boolean): HttpHeaders {
    return new HttpHeaders({
      Authorization: requireAuth ? 'Bearer ' + this.tokenService.getToken() : ''
    });
  }
}
