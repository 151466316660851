import {Component, Input, OnInit} from '@angular/core';
import {Feedback} from '../../../../_model/bean/Feedback';

@Component({
  selector: 'app-feedback-table-column-fields',
  templateUrl: './feedback-table-column-fields.component.html',
  styleUrls: ['./feedback-table-column-fields.component.sass']
})
export class FeedbackTableColumnFieldsComponent implements OnInit {
// tslint:disable-next-line:variable-name
  private _feedback: Feedback | null;

  constructor() {
  }

  ngOnInit() {
  }

  @Input()
  set feedback(feedback: Feedback | null) {
    this._feedback = feedback;
  }

  get feedback(): Feedback | null {
    return this._feedback;
  }

}
