import {MatSnackBar} from '@angular/material';
import {OnInit} from '@angular/core';
import {AuthService} from '../_service/auth/auth.service';
import {NavigationService} from '../_service/navigation/navigation.service';
import {HttpErrorResponse} from '@angular/common/http';
import {UserService} from '../_service/user/user.service';

export abstract class AbstractPageComponent implements OnInit {

  protected constructor(protected userService: UserService,
                        protected snackBar: MatSnackBar,
                        protected authService: AuthService,
                        protected navService: NavigationService) {
  }

  ngOnInit(): void {
    if (this.requireAuth() && !this.authService.isAuthenticated()) {
      this.navService.toLogin();
    }
  }

  protected showSnackBar(text: string) {
    this.snackBar.open(text, null, {duration: 2000});
  }

  protected requireAuth(): boolean {
    return true;
  }

  protected onHttpError(error: HttpErrorResponse) {
    if (error.status === 401) {
      this.userService.deleteUser();
      this.authService.logout();
      this.navService.toLogin();
    }
  }
}
