import {Component, OnInit} from '@angular/core';
import {AbstractPageComponent} from '../../_component/AbstractPageComponent';
import {MatSnackBar} from '@angular/material';
import {AuthService} from '../../_service/auth/auth.service';
import {NavigationService} from '../../_service/navigation/navigation.service';
import {ActivatedRoute} from '@angular/router';
import {BranchService} from '../../_service/company/branch.service';
import {Branch} from '../../_model/bean/Branch';
import {ColorLetters} from '../../_model/ColorLetters';
import {Company} from '../../_model/bean/Company';
import {UserService} from '../../_service/user/user.service';
import {Terminal} from '../../_model/bean/Terminal';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-branch-details',
  templateUrl: './branch-details.component.html',
  styleUrls: ['./branch-details.component.sass']
})
export class BranchDetailsComponent extends AbstractPageComponent implements OnInit {

  branch: Branch;
  terminalsOnlineCount = 0;
  company: Company;
  colorLetters = new ColorLetters();
  isLoading = true;

  constructor(private route: ActivatedRoute,
              private branchService: BranchService,
              userService: UserService,
              snackBar: MatSnackBar,
              authService: AuthService,
              navService: NavigationService) {
    super(userService, snackBar, authService, navService);
    this.route.params.subscribe(params => this.loadData(params.id));
  }

  ngOnInit() {
  }

  private isTerminalOnline(terminal: Terminal): boolean {
    return Date.now() - terminal.lastSeen < environment.terminalBeforeOfflineIntervalInMillis;
  }

  private loadData(branchId: number) {
    this.userService.getUser().subscribe(user => this.company = user.company);

    this.branchService.getById(branchId).subscribe(branch => {
        this.branch = branch;
        this.calculateOnlineTerminals(this.branch.terminals);
      },
      error => this.onHttpError(error),
      () => this.isLoading = false);
  }

  private calculateOnlineTerminals(terminals: Terminal[]) {
    terminals.forEach(terminal => {
      if (this.isTerminalOnline(terminal)) {
        this.terminalsOnlineCount++;
      }
    });
  }
}
