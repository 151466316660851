import {Injectable} from '@angular/core';
import {AbstractRestService} from '../abstract-rest.service';
import {HttpClient} from '@angular/common/http';
import {AuthTokenService} from '../auth/auth-token.service';
import {Observable} from 'rxjs';
import {Branch} from '../../_model/bean/Branch';
import {Terminal} from '../../_model/bean/Terminal';

@Injectable({
  providedIn: 'root'
})
export class TerminalService extends AbstractRestService {

  constructor(httpClient: HttpClient, tokenService: AuthTokenService) {
    super(httpClient, tokenService);
  }

  getByBranch(branch: Branch): Observable<Terminal[]> {
    return this.httpGet<Terminal[]>('company/branch/' + branch.id + '/terminal');
  }

  getAll(): Observable<Terminal[]> {
    return this.httpGet<Terminal[]>('company/terminal');
  }
}
