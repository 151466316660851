import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FeedbackTableColumn} from '../../../_model/FeedbackTableColumn';
import {MatCheckboxChange} from '@angular/material';

@Component({
  selector: 'app-feedback-table-setting-columns',
  templateUrl: './feedback-table-settings.component.html',
  styleUrls: ['./feedback-table-settings.component.sass']
})
export class FeedbackTableSettingsComponent implements OnInit {

  @Output() itemChecked = new EventEmitter<FeedbackTableColumn>();
  @Output() itemUnchecked = new EventEmitter<FeedbackTableColumn>();
  @Input() columns: FeedbackTableColumn[] = [];

  constructor() {
  }

  ngOnInit() {
  }

  toggleColumn(event: MatCheckboxChange, column: FeedbackTableColumn) {
    column.enabled = event.checked;
    if (column.enabled) {
      this.itemChecked.emit(column);
    } else {
      this.itemUnchecked.emit(column);
    }
  }
}
