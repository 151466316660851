import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Observable} from 'rxjs';
import {Branch} from '../../../../_model/bean/Branch';
import {BranchService} from '../../../../_service/company/branch.service';

@Component({
  selector: 'app-feedback-table-filter-branch',
  templateUrl: './feedback-filter-branch.component.html'
})
export class FeedbackFilterBranchComponent implements OnInit {
  @Output() branchSelected = new EventEmitter<Branch | null>();

  $branches: Observable<Branch[]>;
  selectedBranch: Branch | null;

  constructor(private branchService: BranchService) {
  }

  ngOnInit() {
    this.$branches = this.branchService.getAll();
  }

  selectBranch(branch: Branch) {
    this.selectedBranch = branch;
    this.branchSelected.emit(branch);
  }
}
