import { Injectable, NgZone } from '@angular/core';

declare var XLSX: any;
declare var saveAs: any;

export enum ExportImportFormat {
    XLSX = 'XLSX',
    CSV  = 'CSV'
}

export interface ConfigField {
  fieldName: string;
  label: string;
  exportLabel?: string;
  exportIndex?: number;
  index?: number;
  type: string;
  filter?: boolean;
  grid?: boolean;
  view?: boolean;
  enumName?: string;
}

@Injectable({
    providedIn: 'root'
})
export class ExportService {

    constructor(
        private readonly zone: NgZone,
    ) {}

    export(data: any[], columns: ConfigField[], format: ExportImportFormat, header: string[], name?: string): void {
        const newJson = this.buildJSON(data, columns, header);

        switch (format) {
            case ExportImportFormat.CSV: {
                this.zone.runOutsideAngular(() => {
                    const worksheet = XLSX.utils.json_to_sheet(newJson, { header });
                    const workbook  = { Sheets: { data: worksheet }, SheetNames: ['data'] };
                    XLSX.utils.book_append_sheet(workbook, worksheet, 'export');
                    XLSX.writeFile(workbook, this.toExportFileName(name, 'csv'));
                });

                break;
            }

            case ExportImportFormat.XLSX: {
                this.zone.runOutsideAngular(() => {
                    const wscols = [];
                    // tslint:disable-next-line
                    for (let i = 0; i < header.length; i++) {
                        // columns length added
                        wscols.push({ wch: `${header[i]}`.length + 1 });
                    }

                    const worksheet    = XLSX.utils.json_to_sheet(newJson, { header });
                    worksheet['!cols'] = wscols;
                    const workbook     = { Sheets: { data: worksheet }, SheetNames: ['data'] };
                    XLSX.writeFile(workbook, this.toExportFileName(name, 'xlsx'));
                });

                break;
            }

            default:
                throw Error(`Undefined export format "${format}"`);
        }
    }

    private toExportFileName(excelFileName: string, ext: string): string {
        return `${excelFileName}_export_${new Date().getTime()}.${ext}`;
    }

    private buildJSON(rows: any[], columns: ConfigField[], header: string[]): any {
        const columnMap = columns
            .reduce((accum: Map<string, string>, column: ConfigField) => {
                const label = String(column.exportLabel || column.label).trim();

                if (header.includes(label)) {
                    return accum.set(column.fieldName, label);
                }
                return accum;

            }, new Map());

        const array = [];

        for (const item of rows) {
            const newItem = Object.keys(item).reduce((accum, key) => {
                if (!columnMap.has(key)) {
                    return accum;
                }
                let value = item[key];
                if (typeof item[key] === 'boolean') {
                    value = item[key] ? 'true' : 'false';
                }
                return {
                    ...accum,
                    [columnMap.get(key)]: value
                };
            }, {});

            array.push(newItem);
        }

        return array;
    }
}
