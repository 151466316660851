import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable} from 'rxjs';
import {QuestionService} from '../../../../_service/company/question.service';
import {Question} from '../../../../_model/bean/Question';
import {Branch} from '../../../../_model/bean/Branch';

@Component({
  selector: 'app-feedback-table-filter-question',
  templateUrl: './feedback-filter-question.component.html'
})
export class FeedbackFilterQuestionComponent implements OnInit {
  @Output() questionSelected = new EventEmitter<Question | null>();
  // tslint:disable-next-line:variable-name
  private _branch: Branch | null;

  $questions: Observable<Question[]>;
  selectedQuestion: Question | null;

  constructor(
    private questionService: QuestionService) {
  }

  @Input()
  set branch(branch: Branch | null) {
    this._branch = branch;
    if (branch != null) {
      this.$questions = this.questionService.getByBranch(branch);
    } else {
      this.$questions = null;
      this.selectedQuestion = null;
    }
  }

  get branch(): Branch | null {
    return this._branch;
  }

  ngOnInit() {
  }

  selectQuestion(question: Question) {
    this.selectedQuestion = question;
    this.questionSelected.emit(question);
  }
}
